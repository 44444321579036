import {makeStyles} from '@mui/styles';
import {useParams} from 'react-router-dom';
import useSlideshow from '../../../../hooks/useSlideshow';

import SlideTemplateRenderer from '../../../SlideTemplateRenderer/SlideTemplateRenderer';
import mappings from './slideTemplateMappings';

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0
  }
}));

export default function Slide() {
  const classes = useStyles();
  const {slideshowId} = useParams();

  const {getCurrentSlide} = useSlideshow(slideshowId);

  const currentSlide = getCurrentSlide();

  const templateProps = currentSlide?.props;

  const Template = mappings[currentSlide?.template];

  return (
    <div className={classes.root}>
      <SlideTemplateRenderer
        Template={Template}
        templateProps={templateProps}/>
    </div>
  );
}
