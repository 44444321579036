import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import {ThemedLoadingSpinner} from '../../molecules';

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    cursor: props => props.usePointer ? 'pointer' : 'default',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%'
  }
}));

const SlideTemplateRenderer = ({templateProps, Template, onClick}) => {
  const classes = useStyles({
    usePointer: !!onClick
  });

  const handleClick = () => {
    onClick && onClick('something');
  };

  const showSpinner = () => {
    return (templateProps && Template);
  };

  return (showSpinner() ?
    <div
      className={classes.root}
      onClick={handleClick}><Template
        {...templateProps}
      /></div> : <ThemedLoadingSpinner/>
  );
};

SlideTemplateRenderer.propTypes = {
  Template: PropTypes.func,
  onClick: PropTypes.func,
  templateProps: PropTypes.object
};

export default SlideTemplateRenderer;
