import {IconX} from '@tabler/icons-react';
import PropTypes from 'prop-types';

const CloseButton = ({onClick}) => {
  return <div
    className={'flex items-center justify-center p-3 rounded-full h-3 w-3 hover:text-gray-700 hover:bg-gray-100 cursor-pointer'}
    onClick={onClick}>
    <div className={'text-gray-400 opacity-100 z-50 ease-in duration-150'}>
      <IconX/>
    </div>
  </div>;
};

CloseButton.propTypes = {
  onClick: PropTypes.func
};

export default CloseButton;
