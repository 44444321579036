const getCurrentSlideFromSlideshow = (currentSlideshow, event) => {
  if (!currentSlideshow) {
    return {};
  }

  if (currentSlideshow?.editorType === 'board') {
    const roundIndex = event?.activeRound ? event.activeRound : currentSlideshow.activeRound;
    const slideIndex = event?.activeSlide ? event.activeSlide : currentSlideshow.activeSlide;

    return currentSlideshow.round[roundIndex].slides[slideIndex];
  }

  const slideIndex = currentSlideshow.activeSlide;
  return currentSlideshow.slides[slideIndex];
};

export default getCurrentSlideFromSlideshow;
