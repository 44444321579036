import {H2} from '../../../../atoms';
import adminDashboardCounts from '../../../../config/adminDashboardCounts';
import useCommonSettings from '../../../../hooks/useCommonSettings';
import useCompanies from '../../../../hooks/useCompanies';
import useEvents from '../../../../hooks/useEvents';
import usePrebuiltRounds from '../../../../hooks/usePrebuiltRounds';
import useQuestions from '../../../../hooks/useQuestions';
import {DashboardCard} from '../../../../molecules';

// TODO: restyle, ideas: https://s.tmimgcdn.com/scr/1204x1146/67000/advance-statistics-cards-bootstrap-4-admin-statistics-cards-layout-ui-elements_67068-original.jpg
const Dashboard = () => {
  const {events} = useEvents();
  const {questions} = useQuestions();
  const {setting} = useCommonSettings('tags');
  const {companies} = useCompanies();
  const {prebuiltRounds} = usePrebuiltRounds();

  const tags = (setting?.values) ? setting.values : [];

  const countMap = adminDashboardCounts(questions, companies, events, tags, prebuiltRounds);

  return (
    <div>
      <H2>Dashboard</H2>
      <div className={'flex flex-col'}>
        {countMap?.map((row, rowIndex) => (<div
          className={'flex flex-wrap'}
          key={`dashboard-row-${rowIndex}`}>
          {row?.map((rowCard, colIndex) => (<DashboardCard
            Icon={rowCard.Icon}
            className={'mb-4 mr-4'}
            count={rowCard.count}
            key={`dashboard-row-${rowIndex}-num-${colIndex}`}
            link={rowCard.link}
            loading={rowCard.showSpinner}
            text={rowCard.text}
            theme={rowCard.color}
          />))}
        </div>))}
      </div>
    </div>
  );
};

export default Dashboard;
