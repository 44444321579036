import PropTypes from 'prop-types';
import PrintoutBottomNotes from './printoutBottomNotes';
import PrintoutContent from './printoutContent';
import PrintoutHeader from './printoutHeader';
import PrintoutPage from './printoutPage';
import PrintoutRightNotes from './printoutRightNotes';
import PrintoutRound from './printoutRound';
import PrintoutRoundRow from './printoutRoundRow';
import PrintoutRounds from './printoutRounds';

const Printout = ({company}) => {
  return <>
    <PrintoutPage>
      <PrintoutHeader>{company?.name} - Answer Sheet</PrintoutHeader>
      <PrintoutContent>
        <PrintoutRounds>
          <PrintoutRoundRow>
            <PrintoutRound>Round 1</PrintoutRound>
            <PrintoutRound>Round 2</PrintoutRound>
          </PrintoutRoundRow>
          <PrintoutRoundRow>
            <PrintoutRound>Round 3</PrintoutRound>
            <PrintoutRound>Round 4</PrintoutRound>
          </PrintoutRoundRow>
        </PrintoutRounds>
        <PrintoutRightNotes>
          - Notes -
        </PrintoutRightNotes>
      </PrintoutContent>
    </PrintoutPage>
    <PrintoutPage>
      <PrintoutHeader>Back Page</PrintoutHeader>
      <PrintoutContent>
        <PrintoutRounds>
          <PrintoutRoundRow>
            <PrintoutRound>Round 5</PrintoutRound>
            <PrintoutRound>Round 6</PrintoutRound>
          </PrintoutRoundRow>
          <PrintoutBottomNotes>
            - Notes -
          </PrintoutBottomNotes>
        </PrintoutRounds>
      </PrintoutContent>
    </PrintoutPage>
  </>;
};

Printout.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string
  })
};

export default Printout;
