import {LoadingSpinner} from '../../atoms';
import ThemeModel from '../../models/Theme';
import themes from '../../theme/theme';

const ThemedLoadingSpinner = ({theme}) => <LoadingSpinner className={themes[theme].spinnerColor} />;

ThemedLoadingSpinner.propTypes = {
  theme: ThemeModel
};

ThemedLoadingSpinner.defaultProps = {
  theme: 'default'
};

export default ThemedLoadingSpinner;
