import {
  IconBuildingCommunity,
  IconDashboard,
  IconMessageDots,
  IconMessagePlus,
  IconPhoneIncoming,
  IconStack2,
  IconTag
} from '@tabler/icons-react';
import {drawerMenuIconProps} from '../molecules/drawerMenuItem/drawerMenuItem';

const adminNavLinks = [{
  href: '/admin',
  icon: <IconDashboard {...drawerMenuIconProps}/>,
  text: 'Dashboard',
  type: 'link'
}, {
  type: 'divider'
}, {
  href: '/admin/tags',
  icon: <IconTag {...drawerMenuIconProps}/>,
  text: 'Tags',
  type: 'link'
}, {
  href: '/admin/questions',
  icon: <IconMessageDots {...drawerMenuIconProps}/>,
  text: 'Questions',
  type: 'link'
}, {
  href: '/admin/prebuiltRounds',
  icon: <IconStack2 {...drawerMenuIconProps}/>,
  text: 'Prebuilt Rounds',
  type: 'link'
}, {
  href: '/admin/companies',
  icon: <IconBuildingCommunity {...drawerMenuIconProps}/>,
  text: 'Companies',
  type: 'link'
}, {
  type: 'divider'
}, {
  href: '/admin/contacts/requests',
  icon: <IconPhoneIncoming {...drawerMenuIconProps}/>,
  text: 'Contact Requests',
  type: 'link'
}, {
  type: 'divider'
}, {
  href: '/admin/questions/suggested',
  icon: <IconMessagePlus {...drawerMenuIconProps}/>,
  text: 'Suggested Questions',
  type: 'link'
}];

export default adminNavLinks;
