import {TextField} from '@mui/material';
import {IconDeviceFloppy} from '@tabler/icons-react';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory, useParams} from 'react-router-dom';

import {H2} from '../../../../atoms';
import hardcodes from '../../../../config/hardcodes';
import useCompanyUsers from '../../../../hooks/useCompanyUsers';
import useEvent from '../../../../hooks/useEvent';
import useEvents from '../../../../hooks/useEvents';
import {ThemedButton} from '../../../../molecules';
import {Select} from '../../../../proteins';
import EditEventDateInput from './editEventDateInput';

const EditEvent = () => {
  const {id: eventId} = useParams();
  const {event, setEvent} = useEvent(eventId);
  const [setNewEventDocRef] = useState({});
  const {register, handleSubmit, setValue} = useForm({
    endDtm: event.endDtm,
    name: event.name,
    startDtm: event.startDtm,
    theme: event.theme
  });
  const {addEvent} = useEvents();
  const history = useHistory();
  const {companyUsers} = useCompanyUsers();

  useEffect(() => {
    setValue('name', event.name);
    setValue('endDtm', event.endDtm);
    setValue('startDtm', event.startDtm);
    setValue('theme', event.theme);
  }, [event]);

  const saveEventData = async data => {
    if (eventId) {
      await setEvent({
        ...data,
        companyId: hardcodes.companyId,
        endDtm: new Date(data.endDtm),
        startDtm: new Date(data.startDtm)
      });
    } else {
      const document = await addEvent({
        ...data,
        companyId: hardcodes.companyId,
        endDtm: new Date(data.endDtm),
        startDtm: new Date(data.startDtm)
      });
      setNewEventDocRef(document);
    }

    history.push(`/app/events/${document.id}`);
  };

  return <>
    <H2>New Event</H2>
    <form
      className={'flex'}
      noValidate
      onSubmit={handleSubmit(saveEventData)}>
      <div className={'flex flex-col w-full'}>
        <div className={'mb-4 w-full'}>
          <TextField
            fullWidth
            id="name"
            label="Event Name"
            {...register('name', {maxLength: 50, required: true})}/>
        </div>
        <div className={'mb-4 w-full'}>
          <TextField
            fullWidth
            id="theme"
            label="Event Theme"
            {...register('theme', {maxLength: 50})}/>
        </div>
        <div className={'mb-4 w-full'}>
          <div className={'flex'}>
            <EditEventDateInput
              fieldName={'startDtm'}
              label={'Start Time'}
              register={register}
            />
            <EditEventDateInput
              fieldName={'endDtm'}
              label={'End Time'}
              register={register}
            />
          </div>
        </div>
        <div className={'mb-4 w-full'}>
          <div className={'flex'}>
            <Select
              multi
              options={companyUsers?.map(user => ({ label: user.data().name, value: user.id}))}
              {...register('hosts')} />
          </div>
        </div>
        <div className={'mb-4'}>
          <div className={'flex justify-end w-full'}>
            <ThemedButton
              theme={'primary'}
              type={'submit'}>
              <IconDeviceFloppy strokeWidth={1}/> Save
            </ThemedButton>
          </div>
        </div>
      </div>
    </form>
  </>;
};

export default EditEvent;
