import {doc, getFirestore} from 'firebase/firestore';
import {useDocument} from 'react-firebase-hooks/firestore';
import {firebaseApp} from '../firebaseApp';

const useTriviaDocument = (path, documentId) => {
  if (path && documentId) {
    return useDocument(
      doc(getFirestore(firebaseApp), path, documentId),
      {
        snapshotListenOptions: {includeMetadataChanges: true}
      }
    );
  }

  return [];
};

export default useTriviaDocument;
