import {IconCheck, IconPencil, IconX} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import {useState} from 'react';

import {P} from '../../atoms';
import {noop} from '../../utils';

const HiddenInput = ({onCancel, onSave, text, Tag}) => {
  const [inputValue, setInputValue] = useState(text);
  const [hovered, setHovered] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  const enterEditMode = () => {
    setEditMode(true);
  };

  const exitEditMode = () => {
    setEditMode(false);
  };

  const saveClicked = () => {
    exitEditMode();
    onSave();
  };

  const cancelClicked = () => {
    exitEditMode();
    onCancel();
  };

  const inputChanged = event => {
    setInputValue(event.target.value);
  };

  return <div
    className={'flex inline-flex items-center'}
    data-testId={'hidden-input-container'}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}>
    {editMode ? <div className={'flex inline-flex items-center'}>
      <input
        className={'border border-gray-700 rounded-l p-2'}
        onChange={inputChanged}
        value={inputValue}/>
      <div
        className={'border-y border-y-gray-300 cursor-pointer p-2 hover:text-sky-500 hover:bg-gray-300'}
        data-testid={'cancel-button'}
        onClick={cancelClicked}
        role={'button'}>
        <IconX/>
      </div>
      <div
        className={'border border-gray-300 rounded-r cursor-pointer p-2 hover:text-sky-500 hover:bg-gray-300'}
        data-testid={'save-button'}
        onClick={saveClicked}
        role={'button'}>
        <IconCheck/>
      </div>
    </div> : <Tag>
      {text}
    </Tag>}
    {(hovered && !editMode) && <div>
      <IconPencil
        className={'cursor-pointer'}
        onClick={enterEditMode}/>
    </div>}
  </div>;
};

HiddenInput.propTypes = {
  Tag: PropTypes.element,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  text: PropTypes.string
};

HiddenInput.defaultProps = {
  Tag: P,
  onCancel: noop,
  onSave: noop,
  text: ''
};

export default HiddenInput;
