import hardcodes from '../config/hardcodes';

export const addNewSlideshow = (companyId = hardcodes.companyId) => {
  // eslint-disable-next-line no-unused-vars
  const newSlideshow = {
    activeRound: 0,
    activeSlide: 0,
    companyId,
    editorType: 'board',
    name: 'New Slideshow',
    round: []
  };

  // save slideshow
};

export const addRoundToSlideshow = (slideshow, setSlideshow, slideshowId) => prebuiltRoundId => {
  let number = '', lastRound;

  try {
    lastRound = parseInt(slideshow.round[slideshow.round.length - 1].number);
  } catch (ex) {
    console.error(ex);
  }

  if (typeof lastRound.number === 'number') {
    number = lastRound.number + 1;
  }

  slideshow.round.push({
    number,
    prebuiltRoundId,
    questionCount: 0,
    slides: []
  });

  slideshow.rounds = slideshow.round.length;

  setSlideshow(slideshow, slideshowId);
};

export const getNextSlideIndexes = () => {

};

export const getPreviousSlideIndexes = slideshow => {
  let returnVal = {
    activeRound: slideshow.activeRound,
    activeSlide: slideshow.activeSlide
  };

  if (slideshow.activeRound > 0 && slideshow.activeSlide > 0) {
    // do stuff
  }

  return returnVal;
};
