import {IconAlertTriangle} from '@tabler/icons-react';
import PropTypes from 'prop-types';

import {H3} from '../../atoms';
import CloseButton from '../closeButton/closeButton';
import {ThemedButton} from '../index';

const Dialog = ({onClose, open}) => {
  return open && <div
    className="fixed z-[1000] inset-0 overflow-y-auto pl-[60px] l-0 r-0 t-0 b-0"
    role="dialog">
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>

      <span className="hidden sm:inline-block sm:align-middle sm:h-[50vh]">&#8203;</span>

      <div
        className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div className="bg-white px-4 pt-4 pb-4 sm:p-6 sm:pb-4">
          <div className={'flex flex-row-reverse'}>
            <CloseButton onClick={onClose}/>
          </div>
          <div className="flex flex-col md:flex-row">
            <div className={'md:pt-4'}>
              <div
                className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <IconAlertTriangle className={'h-6 w-6 text-red-600'}/>
              </div>
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <H3>Deactivate account</H3>
              <div className="mt-2">
                <div className="text-sm text-gray-500">Are you sure you want to deactivate your account? All of your
                  data will be permanently removed. This action cannot be undone.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <ThemedButton
            className={'ml-4'}
            theme={'secondary'}>Deactivate</ThemedButton>
          <ThemedButton theme={'default'}>Cancel</ThemedButton>
        </div>
      </div>
    </div>
  </div>;
};

Dialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default Dialog;
