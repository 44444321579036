import ChildrenModel from '../../models/Children';
import {Dialog} from '../../molecules';

const DialogWrapper = ({children, ...rest}) => {

  return <><Dialog
    onClose={close}
    open={false}
    {...rest} />{children}</>;
};

DialogWrapper.propTypes = {
  children: ChildrenModel
};

export default DialogWrapper;
