import {useParams} from 'react-router-dom';

import useCompany from '../../../../../hooks/useCompany';
import useSlideshow from '../../../../../hooks/useSlideshow';
import Printout from './printout';
import './printout.scss';

const FirestorePrintout = () => {
  const {slideshowId} = useParams();
  const {slideshow} = useSlideshow(slideshowId);
  const {company} = useCompany();

  if (slideshow?.editorType !== 'board') {
    alert('Stuff is about to break!');
  }

  return <Printout company={company}/>;
};

export default FirestorePrintout;
