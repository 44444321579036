import {doc, getFirestore, setDoc} from 'firebase/firestore';

import {firebaseApp} from '../firebaseApp';
import useTriviaDocument from './useTriviaDocument';

const useEvent = eventId => {
  const [document, loading, error] = useTriviaDocument('events', eventId);

  const event = document ? document.data() : {};

  const setEvent = async (event, eventId) => {
    return await setDoc(doc(getFirestore(firebaseApp), 'events', eventId), event);
  };

  return {
    event: {...event, id: eventId},
    eventError: error,
    eventLoading: loading,
    setEvent
  };
};

export default useEvent;
