import PropTypes from 'prop-types';

import ChildrenModel from '../../models/Children';
import ThemeModel from '../../models/Theme';
import ThemedLoadingSpinner from '../themedLoadingSpinner/themedLoadingSpinner';

const ConditionalSpinner = ({children, showSpinnerWhen, theme}) => showSpinnerWhen ? <ThemedLoadingSpinner theme={theme}/> : children;

ConditionalSpinner.propTypes = {
  children: ChildrenModel,
  showSpinnerWhen: PropTypes.bool,
  theme: ThemeModel
};

ConditionalSpinner.defaultProps = {
  children: <div />
};

export default ConditionalSpinner;
