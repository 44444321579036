import {Button, TextField} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {IconDeviceFloppy} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {Paper} from '../../atoms';

import useSlideTemplates, {useSlideTemplateProps} from '../../hooks/useSlideTemplates';
import {ConditionalSpinner} from '../../molecules';
import {Select} from '../../proteins';

const useStyles = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  editForm: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  slideEditor: {
    // height: '240px',
    flexGrow: '1',
    margin: '1em 2em',
    overflowY: 'show',
    padding: '1em'
    // width: '30%',
  }
}));

const SlideEditor = ({slide, cancelButtonClicked, saveSlide}) => {
  const classes = useStyles();
  const [template, setTemplate] = useState(slide?.template);
  const {slideTemplates, loading} = useSlideTemplates();
  const [props, setProps] = useState(slide?.props);
  const {slideTemplateProps, slideTemplateLoading} = useSlideTemplateProps(template);

  const templateChanged = event => {
    setTemplate(event);
  };

  const changeInput = propName => event => {
    const newProps = {};

    slideTemplateProps.forEach(prop => {
      if (props[prop.data().propName]) {
        newProps[prop.data().propName] = props[prop.data().propName];
      }
    });

    newProps[propName] = event.target.value;

    setProps(newProps);
  };

  const saveButtonClicked = () => {
    saveSlide({
      props,
      template
    });
  };

  return <ConditionalSpinner showSpinnerWhen={loading}>
    <Paper
      className={classes.slideEditor}
      elevation={3}
    >
      <form className={classes.editForm}>
        <Select
          loading={loading}
          onChange={templateChanged}
          options={slideTemplates?.map(template => ({
            label: template.data().name,
            value: template.id
          }))}
          theme={'primary'}
          value={template.id}
        />
        <ConditionalSpinner showSpinnerWhen={slideTemplateLoading}/>
        {slideTemplateProps?.map(prop => (
          <TextField
            className={classes.formControl}
            id={prop.data().propName}
            key={prop.data().propName}
            label={prop.data().name}
            onChange={changeInput(prop.data().propName)}
            required={prop.data().required}
            value={props[prop.data().propName]}
            variant="outlined"
          />
        ))}
        <div className={classes.buttons}>
          <Button
            className={classes.formControl}
            onClick={cancelButtonClicked}
          >
            Close
          </Button>
          <Button
            className={classes.formControl}
            color="primary"
            onClick={saveButtonClicked}
            startIcon={<IconDeviceFloppy/>}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </form>
    </Paper>
  </ConditionalSpinner>;
};

SlideEditor.propTypes = {
  cancelButtonClicked: PropTypes.func,
  saveSlide: PropTypes.func,
  slide: PropTypes.shape({
    props: PropTypes.object,
    template: PropTypes.string
  })
};

export default SlideEditor;
