import {IconDeviceFloppy, IconExternalLink, IconHeart} from '@tabler/icons-react';
import PropTypes from 'prop-types';

import {Chip, H2, H4} from '../../../../atoms';
import QuestionModel from '../../../../models/Question';
import {ChipList, Rating, ThemedButton} from '../../../../molecules';
import noop from '../../../../utils/noop';

const Question = ({favoriteClicked, getFavoriteChipColor, handleSaveClicked, question}) => {
  return <div className={'flex flex-col'}>
    <div>
      <H2>{question?.text}</H2>
      <Chip
        color={getFavoriteChipColor()}
        label="Favorite"
        onClick={favoriteClicked}
        variant="outlined">
        <IconHeart/>
      </Chip>
    </div>
    <div className={'mb-4'}>
      <H4>Answer(s)</H4>
      <ul>
        {question?.answers?.map(answer => (
          <li key={answer}>{answer}</li>
        ))}
      </ul>
    </div>
    <div className={'mb-4'}>
      <H4>Difficulty</H4>
      <Rating
        readOnly
        value={parseInt(question?.difficulty)}/>
    </div>
    <div className={'mb-4'}>
      <H4>Tags</H4>
      <ChipList
        chips={question?.tags}
      />
    </div>
    <div className={'flex mb-4 w-full'}>
      {question?.source &&
        <ThemedButton
          className={'mr-4'}
          onClick={() => window.location = question?.source}
          theme={'secondary'}><IconExternalLink strokeWidth={1}/> Answer Source</ThemedButton>}
      <ThemedButton
        onClick={handleSaveClicked}
        theme={'primary'}
      ><IconDeviceFloppy strokeWidth={1}/> Save</ThemedButton>
    </div>
  </div>;
};

Question.propTypes = {
  favoriteClicked: PropTypes.func,
  getFavoriteChipColor: PropTypes.func,
  handleSaveClicked: PropTypes.func,
  question: QuestionModel
};

Question.defaultProps = {
  favoriteClicked: noop,
  getFavoriteChipColor: noop,
  handleSaveClicked: noop
};

export default Question;
