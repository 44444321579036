const siteNavLinks = [{
  text: 'Home',
  url: '/'
}, {
  hash: '#features',
  text: 'Features',
  url: '/'
}, {
  text: 'Pricing',
  url: '/pricing'
}, {
  text: 'FAQs',
  url: '/'
}, {
  text: 'Contact',
  url: '/contact'
}, {
  text: 'Team',
  url: '/team'
}, {
  text: 'Login',
  url: '/app'
}];

export default siteNavLinks;
