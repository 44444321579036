import siteNavLinks from '../../config/siteNavLinks';
import SiteNavLink from '../siteNavLink/siteNavLink';

const SiteHeader = () => {
  return (
    <div className={'flex w-full'}>
      <header className={'flex flex-wrap justify-center py-3 mb-4 border-b w-full'}>
        <a
          className={'flex items-center p-2 mb-0 text-dark text-decoration-none'}
          href="/">
          <span className="fs-4">Trivia MC</span>
        </a>

        <ul className={'flex flex-grow justify-end'}>
          {siteNavLinks?.map((link, index) => (
            <SiteNavLink
              key={`site-nav-link-${index}`}
              link={link}
              linkClasses={'px-2 text-muted'}
            />
          ))}
        </ul>
      </header>
    </div>
  );
};

export default SiteHeader;
