import PropTypes from 'prop-types';

import ChildrenModel from '../../models/Children';

const SlideWrapper = ({backgroundColor, children, color, ...rest}) => {
  return (
    <div
      className={`h-full w-full px-0 ${backgroundColor} ${color}`}
      {...rest}>
      {/* eslint-disable-next-line react/prop-types */}
      {children}
    </div>
  );
};

SlideWrapper.propTypes = {
  backgroundColor: PropTypes.string,
  children: ChildrenModel,
  color: PropTypes.string
};

export default SlideWrapper;
