import {useParams} from 'react-router-dom';

import FirestoreLinearSlideshow from '../../../linearSlideshow/firestoreLinearSlideshow';

const SlideshowDesigner = () => {
  const {slideshowId} = useParams();

  return <FirestoreLinearSlideshow slideshowId={slideshowId}/>;
};

export default SlideshowDesigner;
