import {useParams} from 'react-router-dom';

import hardcodes from '../../../../config/hardcodes';
import useQuestion from '../../../../hooks/useQuestion';
import useUser from '../../../../hooks/useUser';
import {ConditionalSpinner} from '../../../../molecules';
import Question from './question';

const FirestoreQuestion = () => {
  const {questionId} = useParams();
  const {question, questionLoading, saveQuestion} = useQuestion(questionId);
  const {user, setUser} = useUser(hardcodes.userId);

  const getIndexOfQuestionInFavorites = () => {
    return user?.favoriteQuestions?.indexOf(questionId);
  };

  const isFavorite = () => {
    return getIndexOfQuestionInFavorites() > -1;
  };

  const favoriteClicked = () => {
    if (isFavorite()) {
      user.favoriteQuestions.splice(getIndexOfQuestionInFavorites(), 1);
    } else {
      user.favoriteQuestions.push(questionId);
    }

    setUser(user, hardcodes.userId);
  };

  const getFavoriteChipColor = () => {
    return isFavorite() ? 'error' : 'default';
  };

  const handleSaveClicked = () => {
    saveQuestion(question);
  };

  return <ConditionalSpinner showSpinnerWhen={questionLoading}>
    <Question
      favoriteClicked={favoriteClicked}
      getFavoriteChipColor={getFavoriteChipColor}
      handleSaveClicked={handleSaveClicked}
      question={question}/>
  </ConditionalSpinner>;
};

export default FirestoreQuestion;
