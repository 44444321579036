import {IconChevronLeft, IconChevronRight} from '@tabler/icons-react';
import dayjs from 'dayjs';
import * as localeData from 'dayjs/plugin/localeData';
import PropTypes from 'prop-types';
import {useState} from 'react';

import {noop} from '../../utils';
import {ThemedIconButton} from '../index';

dayjs.extend(localeData);

const SmallCalendar = ({onClick, selectedDate}) => {
  const [stateMonth, setStateMonth] = useState(selectedDate.month());
  const [stateYear, setStateYear] = useState(selectedDate.year());

  const firstOfMonth = dayjs().year(stateYear).month(stateMonth).date(1);
  const lastOfMonth = firstOfMonth.add(1, 'month').subtract(1, 'day');

  const leadDays = firstOfMonth.day();
  const tailDays = 6 - lastOfMonth.day();

  const data = [];

  const headings = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  for (let i = 0; i < leadDays; i++) {
    data.push('');
  }

  for (let i = 0; i < lastOfMonth.date(); i++) {
    data.push((i + 1).toString());
  }

  for (let i = 0; i < tailDays; i++) {
    data.push('');
  }

  const matrix = [[]];

  let row = 0;
  let col = 0;

  data.forEach(item => {
    if (!matrix[row]) {
      matrix[row] = [];
    }

    matrix[row][col] = item;
    col++;

    if (col > 6) {
      col = 0;
      row++;
    }
  });

  const dayClicked = day => () => {
    onClick(dayjs().year(stateYear).month(stateMonth).date(day).toDate());
  };

  const decrementMonth = () => {
    if (stateMonth === 1) {
      setStateMonth(12);
      setStateYear(stateYear-1);
      return;
    }
    setStateMonth(stateMonth-1);
  };

  const incrementMonth = () => {
    if (stateMonth === 12) {
      setStateMonth(1);
      setStateYear(stateYear+1);
      return;
    }

    setStateMonth(stateMonth+1);
  };

  const isSelectedDate = col => {
    return selectedDate.month() === stateMonth && selectedDate.year() === stateYear && selectedDate.date().toString() === col;
  };

  const isToday = col => {
    const today = dayjs();
    return today.month() === stateMonth && today.year() === stateYear && today.date().toString() === col;
  };

  return <div className={'flex flex-col'}>
    <div className={'flex'}>
      <ThemedIconButton
        onClick={decrementMonth}
        theme={'default'}>
        <IconChevronLeft
          height={24}
          strokeWidth={1}
          width={24}/>
      </ThemedIconButton>
      <div className={'flex flex-grow items-center justify-center'}>
        {dayjs.localeData().months()[stateMonth]} {stateYear}
      </div>
      <ThemedIconButton
        onClick={incrementMonth}
        theme={'default'}>
        <IconChevronRight
          height={24}
          strokeWidth={1}
          width={24}/>
      </ThemedIconButton>
    </div>
    <div className={'flex'}>
      {headings?.map((heading, index) => <div
        className={'flex items-center justify-center rounded-full h-10 w-10 p-3'}
        key={`heading-${index}`}>
        {heading}
      </div>)}
    </div>
    <div className={'flex flex-col rounded-xl border-1 border-gray-400 h-fit w-fit'}>
      {matrix?.map((row, rowIndex) => <div
        className={'flex border-b-1 border-gray-400 '}
        key={`week-${rowIndex}`}>
        {row?.map((col, colIndex) => <div
          className={`flex justify-center items-center h-10 w-10 ${rowIndex === 0 && 'border-b-1 border-gray-400'} ${!col.length && 'bg-gray-100'} ${(colIndex + 1 === row.length && !isToday(col)) && 'rounded-br-xl'} ${rowIndex === 0 && colIndex === 0 && 'rounded-tl-xl'} ${isToday(col) ? 'border-2 border-blue-400' : ''}`}
          key={`week-${rowIndex}-day-${colIndex}`}>
          <div
            className={col.length && `flex items-center justify-center rounded-full hover:bg-sky-400 hover:text-white cursor-pointer h-10 w-10 p-3 ${isSelectedDate(col) ? 'bg-sky-200' : ''}`}
            onClick={dayClicked(col)}>
            {col}
          </div>
        </div>)}
      </div>)}
    </div>
  </div>;
};

SmallCalendar.propTypes = {
  onClick: PropTypes.func,
  selectedDate: PropTypes.object
};

SmallCalendar.defaultProps = {
  onClick: noop,
  selectedDate: dayjs()
};

export default SmallCalendar;
