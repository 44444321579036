import useTriviaDocument from './useTriviaDocument';

const useVenue = venueId => {
  const [document, loading, error] = useTriviaDocument('venues', venueId);

  const venue = document?.data();

  return {
    venue,
    venueError: error,
    venueLoading: loading
  };
};

export default useVenue;