import {IconStar} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import {noop} from '../../utils';

const Rating = ({max, readOnly, onChange, value}) => {
  const stars = [];

  for (let i = 0; i < max; i++) {
    if (i < value) {
      stars.push(true);
    } else {
      stars.push(false);
    }
  }

  const starClicked = index => () => {
    if (!readOnly) {
      onChange(index+1);
    }
  };

  return <ul className="flex">
    {stars?.map((star, index) => <li
      key={`star-${index}`}
      onClick={starClicked(index)}>
      <IconStar className={`text-amber-500 ${star && 'fill-amber-500'} ${(!readOnly) && 'hover:fill-amber-500'}`}/>
    </li>)}
  </ul>;
};

Rating.propTypes = {
  max: PropTypes.number,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.number
};

Rating.defaultProps = {
  max: 5,
  onChange: noop,
  readOnly: false,
  value: 0
};

export default Rating;
