import {createTheme, CssBaseline, ThemeProvider, useTheme} from '@mui/material';
import {useState} from 'react';

import customerNavLinks from '../../../../config/customerNavLinks';
import hardcodes from '../../../../config/hardcodes';
import useBoardSlideshow from '../../../../hooks/useBoardSlideshow';
import useCompany from '../../../../hooks/useCompany';
import ChildrenModel from '../../../../models/Children';
import {AppBar} from '../../../../molecules';
import {DialogWrapper} from '../../../../proteins';
import themeOptions from '../../../../themeOptions';
import CommonAdminDrawer from '../../../commonAdminDrawer/commonAdminDrawer';
import FirestoreSlideshowControls from './firestoreSlideshowControls';

const mdTheme = createTheme(themeOptions);

const CustomerAdminWrapper = (props) => {
  useTheme();

  const [drawerOpen, setDrawerOpen] = useState(true);
  const {company} = useCompany(hardcodes.companyId);
  const {getCurrentSlide, previousSlide, nextSlide} = useBoardSlideshow(hardcodes.slideshowId);

  const currentSlide = getCurrentSlide();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return <DialogWrapper>
    <ThemeProvider theme={mdTheme}>
      <div className={'flex w-full h-full'}>
        <CssBaseline/>
        <AppBar text={company?.name}/>
        <CommonAdminDrawer
          links={customerNavLinks}
          open={drawerOpen}
          toggleDrawer={toggleDrawer}
        />
        <main
          className={'grow-1 w-full p-4 ml-[60px] mt-[56px] b-0 r-0'}>
          {props.children}
        </main>
        <FirestoreSlideshowControls
          company={company}
          currentSlide={currentSlide}
          goToPreviousSlide={previousSlide}
          onClick={nextSlide}
        />
      </div>
    </ThemeProvider>
  </DialogWrapper>;
};

CustomerAdminWrapper.propTypes = {
  children: ChildrenModel
};

export default CustomerAdminWrapper;

// '    marginLeft: \'60px\',\n' +
// '    marginTop: \'56px\',\n' +
// '    overflow: \'scroll\',\n' +
// '    padding: \'24px\''