import {addDoc, collection, getFirestore} from 'firebase/firestore';

import {firebaseApp} from '../firebaseApp';
import useTriviaCollection from './useTriviaCollection';

export const COMPANY_PATH = 'companies';

const useCompanies = () => {
  const [companies, loading, error] = useTriviaCollection(COMPANY_PATH);

  const addCompany = async newCompany => {
    return await addDoc(collection(getFirestore(firebaseApp), COMPANY_PATH), newCompany);
  };

  return {
    addCompany,
    companies: companies ? companies.docs : [],
    error,
    loading
  };
};

export default useCompanies;
