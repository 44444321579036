import {IconEye} from '@tabler/icons-react';
import PropTypes from 'prop-types';

import {Switch} from '../../../../atoms';
import {ThemedTooltipIconButton} from '../../../../molecules';

const ScorecardRow = props => {
  return <div className={'flex items-center mt-[12px] w-full'}>
    <div className={'flex w-1/6'}>
      #{props.question}
    </div>
    <div className={'flex items-center w-1/6'}>
      <Switch/>
    </div>
    <div className={'flex items-center w-1/3'}>
      <ThemedTooltipIconButton
        text={`Answer ${props.question}`}>
        <IconEye strokeWidth={1}/>
      </ThemedTooltipIconButton>
    </div>
    <div className={'flex w-1/3'}>
      <input
        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        placeholder="Points"
        type="text"
      />
    </div>
  </div>;
};

ScorecardRow.propTypes = {
  question: PropTypes.number
};

export default ScorecardRow;
