import PropTypes from 'prop-types';
import Children from '../../models/Children';
import getButtonClasses from '../../theme/getButtonClasses';

const IconButton = ({children, className, onClick, shape, ...rest}) => {

  let shapeClassName = 'rounded-full';

  if (shape === 'square') {
    shapeClassName = 'rounded';
  }

  return <button
    className={`flex justify-center items-center h-[48px] w-[48px] cursor-pointer ${shapeClassName} text-black p-2 ${className} disabled:opacity-75 disabled:bg-blend-darken disabled:cursor-not-allowed`}
    onClick={onClick}
    type='button'
    {...rest}>{children}</button>;
};

IconButton.propTypes = {
  children: Children,
  className: PropTypes.string,
  onClick: PropTypes.func,
  shape: PropTypes.string
};

IconButton.defaultProps = {
  className: getButtonClasses('default'),
  shape: 'round'
};

export default IconButton;
