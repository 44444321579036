import PropTypes from 'prop-types';

import {H5} from '../../../../atoms';
import ChildrenModel from '../../../../models/Children';

const CompanyCard = ({children, heading}) => {
  return <div className={'shadow-md p-4'}>
    <H5>{heading}</H5>
    {children}
  </div>;
};

CompanyCard.propTypes = {
  children: ChildrenModel,
  heading: PropTypes.string
};

export default CompanyCard;
