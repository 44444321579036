import PropTypes from 'prop-types';

import RoundQuestionFooter from './roundQuestionFooter';
import SlideWrapper from './slideWrapper';

const Question = ({text, round, question, small, ...rest}) => {
  return (
    <SlideWrapper {...rest}>
      <div className={'flex flex-col justify-between h-full w-full'}>
        <div className={`font-thin ${small ? 'text-2xl' : 'text-6xl'}`}>{text}</div>
        <RoundQuestionFooter
          question={question}
          round={round}/>
      </div>
    </SlideWrapper>
  );
};

Question.propTypes = {
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  round: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  small: PropTypes.bool,
  text: PropTypes.string.isRequired
};

Question.defaultProps = {
  small: false
};

export default Question;
