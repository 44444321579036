import {get as _get} from 'lodash';

export const getRoundHeader = (round, prebuiltRound) => {
  if (prebuiltRound) {
    return prebuiltRound.title;
  }

  if (typeof round.number === 'number') {
    return <>Round {round.number}</>;
  }

  return round.number;
};

export const addSlideToRound = (slideshow, setSlideshow, slideshowId) => (round, newSlide = {}) => {
  if (_get(slideshow, `round[${round}].slides`)) {
    slideshow.round[round].slides.push(newSlide);

    return setSlideshow(slideshow, slideshowId);
  }
};

export const deleteSlideFromRound = (slideshow, setSlideshow, slideshowId) => (round, index) => {
  if (round && round.slides) {
    round.slides.splice(index, 1);
    slideshow.round[round.number] = round;
    setSlideshow(slideshow, slideshowId);
  }
};

export const saveSlideInRound = (slideshow, setSlideshow, slideshowId) => (round, index, value) => {
  slideshow.round[round.number].slides[index] = value;
  setSlideshow(slideshow, slideshowId);
};

export const saveRound = (slideshow, setSlideshow, slideshowId) => round => {
  slideshow.round[round.number] = round;
  setSlideshow(slideshow, slideshowId);
};

export const deleteRound = (slideshow, setSlideshow, slideshowId) => index => {
  slideshow.round.splice(index, 1);

  setSlideshow(slideshow, slideshowId);
};
