import {addDoc, collection, getFirestore} from 'firebase/firestore';
import {useCollection} from 'react-firebase-hooks/firestore';
import {firebaseApp} from '../firebaseApp';

const useSuggestedQuestions = () => {
  const [suggestedQuestions, loading, error] = useCollection(
    collection(getFirestore(firebaseApp), 'suggestedQuestions'),
    {
      snapshotListenOptions: {includeMetadataChanges: true}
    }
  );

  const addSuggestedQuestion = async newSuggestedQuestion => {
    return await addDoc(collection(getFirestore(firebaseApp), 'suggestedQuestions'), newSuggestedQuestion);
  };

  return {
    addSuggestedQuestion,
    error,
    loading,
    suggestedQuestions: suggestedQuestions ? suggestedQuestions.docs : []
  };
};

export default useSuggestedQuestions;
