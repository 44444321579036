import PropTypes from 'prop-types';
import {useState} from 'react';
import {noop} from '../../utils';

const TextField = ({label, multiline, onChange, value, ...rest}) => {
  const [hasFocus, setHasFocus] = useState(false);

  const setFocus = () => setHasFocus(true);

  const loseFocus = () => setHasFocus(false);

  const props = {
    className:'border-none bg-none rounded outline-none focus:border-none focus:outline-none p-1',
    onBlur: loseFocus,
    onChange,
    onFocus: setFocus,
    placeholder: label,
    value,
    ...rest
  };

  return <div className={`flex flex-col rounded border-1 ${ hasFocus ? 'shadow-sm shadow-sky-500 border-sky-600' : 'border-gray-400'} mt-4 p-2 bg-white transition ease-in-out`}>
    <div className={'-mt-6 text-sm bg-white font-medium text-gray-500 w-fit p-1'}>{label}</div>
    {multiline ? <textarea {...props} /> : <input {...props} />}
  </div>;
};

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

TextField.defaultProps = {
  multiline: false,
  onChange: noop
};

export default TextField;
