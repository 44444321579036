import {Button} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {IconPlayerSkipBack, IconPlayerSkipForward} from '@tabler/icons-react';

import hardcodes from '../../../../config/hardcodes';
import useSlideshow from '../../../../hooks/useSlideshow';
import {ThemedButton} from '../../../../molecules';
import SlideTemplateRenderer from '../../../SlideTemplateRenderer/SlideTemplateRenderer';
import mappings from '../slide/slideTemplateMappings';
import ScorecardRow from './scorecardRow';

const useStyles = makeStyles({
  button: {
    flexGrow: 1,
    height: '100px',
    margin: '12px',
    width: '100%'
  },
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  slide: {
    height: '240px'
  },
  slideControls: {
    display: 'flex'
  }
});

const Remote = () => {
  const classes = useStyles();

  const {getCurrentSlide, previousSlide, nextSlide} = useSlideshow(hardcodes.slideshowId);

  const currentSlide = getCurrentSlide();

  const Template = currentSlide && mappings[currentSlide.template];

  return (
    <div className={classes.root}>
      <div className={classes.slide}>
        <SlideTemplateRenderer
          Template={Template}
          templateProps={currentSlide?.props}
        />
      </div>
      <div className={'flex'}>
        <div className={'flex flex-grow'}>
          <Button
            className={classes.button}
            onClick={previousSlide}
            size={'large'}
            startIcon={<IconPlayerSkipBack/>}
            variant="outlined"/>
        </div>
        <div className={'flex flex-grow'}>
          <Button
            className={classes.button}
            endIcon={<IconPlayerSkipForward/>}
            onClick={nextSlide}
            size={'large'}
            variant="outlined"/>
        </div>
      </div>
      <div className={'p-1'}>
        <div className={'flex flex-col'}>
          <div className={'flex w-full'}>
            <div className={'flex w-1/6'}>
              Question
            </div>
            <div className={'flex w-1/6'}>
              Correct?
            </div>
            <div className={'flex w-1/3'}>
              Show Answer
            </div>
            <div className={'flex w-1/3'}>
              Points Awarded
            </div>
          </div>
          <ScorecardRow question={1}/>
          <ScorecardRow question={2}/>
          <ScorecardRow question={3}/>
          <div className={'flex w-full'}>
            <ThemedButton theme={'primary'}>
              Save
            </ThemedButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Remote;
