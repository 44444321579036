import PropTypes from 'prop-types';
import {Chip} from '../../atoms';

const ChipList = ({chips}) => {
  return chips?.map((chip, index) => <Chip
    className={index !== 0 ? 'ml-2' : ''}
    key={`chip-list-${index}`}
    text={chip}/>) || <div/>;
};

ChipList.propTypes = {
  chips: PropTypes.arrayOf(PropTypes.string)
};

export default ChipList;
