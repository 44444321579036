import {IconButton} from '@mui/material';
import {IconPlus} from '@tabler/icons-react';
import {useState} from 'react';

import {H2} from '../../../../atoms';
import useCommonSettings from '../../../../hooks/useCommonSettings';
import {ConditionalSpinner, TextField} from '../../../../molecules';

const Tags = () => {
  const {setting, saveSetting, settingLoading} = useCommonSettings('tags');
  const tags = setting?.values ? setting.values : [];
  const [newTag, setNewTag] = useState('');

  return (
    <div>
      <H2>Tags</H2>
      <ConditionalSpinner showSpinnerWhen={settingLoading}/>
      {tags?.map(tag => (<div key={`${tag}`}>{tag}</div>))}
      <form noValidate>
        <TextField
          label="New Tag Text"
          onChange={event => {
            setNewTag(event.target.value);
          }}
          value={newTag}
        />
        <IconButton
          onClick={async () => {
            tags.push(newTag);
            await saveSetting({values: tags});
            setNewTag('');
          }}>
          <IconPlus/>
        </IconButton>
      </form>
    </div>
  );
};

export default Tags;
