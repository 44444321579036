import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import ChildrenModel from '../../models/Children';

// TODO: add all props to propTypes
const Tooltip = ({effect, children, place, type}) => {
  return <span>
    {children}

    <ReactTooltip
      effect={effect}
      place={place}
      type={type}
    />
  </span>;
};

Tooltip.propTypes = {
  children: ChildrenModel,
  effect: PropTypes.string,
  place: PropTypes.string,
  type: PropTypes.string
};

Tooltip.defaultProps = {
  effect: 'solid',
  place: 'top',
  type: 'dark'
};

export default Tooltip;
