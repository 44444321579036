import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import {CustomPlaceholder} from 'react-placeholder-image';

import {Chip, H2} from '../../../../atoms';
import AddressModel from '../../../../models/Address';
import {ConditionalSpinner, ConditionalTable} from '../../../../molecules';
import Address from './address';
import CompanyCard from './companyCard';

const Company = ({company, companyUsers, loading}) => {

  const dates = [{
    label: 'Member Since',
    value: company?.createdAt
  }, {
    label: 'Account Re-news',
    value: company?.accountRenews
  }];

  const userColumns = [{
    Header: 'Name',
    accessor: 'name'
  }, {
    Header: 'Role',
    accessor: 'role'
  }];

  const getMappedUsers = () => companyUsers?.map(user => ({
    name: user.data().name,
    role: user.data().role
  }));

  return <ConditionalSpinner showSpinnerWhen={loading}>
    <div className={'flex flex-row flex-wrap'}>
      <div className={'flex flex-col md:w-1/3 p-1'}>
        <CustomPlaceholder
          height={200}
          width={200}/>
        <CompanyCard heading={'Billing Address'}>
          <Address address={company?.billingAddress}/>
        </CompanyCard>
        <CompanyCard
          heading={'Users'}
        >
          <ConditionalTable
            columns={userColumns}
            data={getMappedUsers()}
            loading={loading}
          />
        </CompanyCard>
      </div>
      <div className={'md:w-2/3 p-1'}>
        <H2>{company?.name}</H2>
        {company?.active && <Chip
          color="bg-emerald-500"
          text="ACTIVE"/>} <Chip
          color="bg-indigo-500"
          text={`${company?.accountType?.toUpperCase()} ACCOUNT`}/>
        <CompanyCard heading={'E-mail'}>
          <div>
          Account E-mail: {company?.email}
          </div>
        </CompanyCard><CompanyCard heading={'Details'}>
          <table className={'flex flex-col'}>
            {dates?.map((date, index) => (
              <tr
                className={'flex p-2 border-b-1 border-b-gray-400'}
                key={`account-date-${index}`}>
                <th className={'flex-grow font-medium'}>{date.label}</th>
                <td className={'flex-grow font-normal'}>
                  {dayjs(date?.value?.toDate()).format('MMM DD, YYYY')}
                </td>
              </tr>
            ))}
          </table>
        </CompanyCard>
      </div>
    </div>
  </ConditionalSpinner>;
};

Company.propTypes = {
  company: PropTypes.shape({
    accountRenews: PropTypes.object,
    accountType: PropTypes.string,
    active: PropTypes.bool,
    billingAddress: AddressModel,
    createdAt: PropTypes.object,
    email: PropTypes.string,
    name: PropTypes.string
  }),
  companyUsers: PropTypes.array,
  loading: PropTypes.bool
};

export default Company;
