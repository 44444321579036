import useTriviaDocument from './useTriviaDocument';

const useTeam = teamId => {
  const [document, loading, error] = useTriviaDocument('teams', teamId);

  const team = document?.data();

  return {
    team,
    teamError: error,
    teamLoading: loading
  };
};

export default useTeam;
