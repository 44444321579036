import {IconPencil} from '@tabler/icons-react';
import {useHistory} from 'react-router-dom';

import useCompanies from '../../../../hooks/useCompanies';
import {ConditionalTable, ThemedTooltipIconButton} from '../../../../molecules';

const companiesColumns = [{
  Header: 'Name',
  accessor: 'name'
}, {
  Header: 'Actions',
  accessor: 'actions'
}];

const Companies = () => {
  const {companies, loading} = useCompanies();
  const history = useHistory();

  const viewCompany = companyId => () => history.push(`/admin/companies/${companyId}`);

  const getMappedCompanies = () => companies?.map(company => ({
    actions: <ThemedTooltipIconButton
      onClick={viewCompany(company?.id)}
      text={'Edit Company'}>
      <IconPencil strokeWidth={1}/>
    </ThemedTooltipIconButton>,
    name: company?.data()?.name
  }));

  return <ConditionalTable
    columns={companiesColumns}
    data={getMappedCompanies()}
    loading={loading}/>;
};

export default Companies;
