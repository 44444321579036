import PropTypes from 'prop-types';
import {MapContainer, TileLayer} from 'react-leaflet';
import {CustomPlaceholder} from 'react-placeholder-image';

import {H2} from '../../../../atoms';
import VenueModel from '../../../../models/Venue';
import {ConditionalSpinner} from '../../../../molecules';
import Address from '../../admin/company/address';
import CompanyCard from '../../admin/company/companyCard';

const Venue = ({venue, venueLoading}) => {
  return <ConditionalSpinner showSpinnerWhen={venueLoading}>
    <div className={'flex flex-row flex-wrap'}>
      <div className={'flex flex-col md:w-1/3 p-1'}>
        <CustomPlaceholder
          height={200}
          width={200}/>
        <CompanyCard heading={'Billing Address'}>
          <Address address={venue?.address}/>
        </CompanyCard>
        <CompanyCard
          heading={'Map'}>
          <MapContainer
            center={[45.4, -75.7]}
            scrollWheelZoom={false}
            zoom={12}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </MapContainer>
        </CompanyCard>
      </div>
      <div className={'flex flex-col md:w-2/3 p-1'}>
        <H2>{venue?.name}</H2>
        <CompanyCard
          heading={'E-mail'}
        >
          <div>
            Contact E-mail: {venue?.contactEmail}
          </div>
        </CompanyCard>
      </div>
    </div>
  </ConditionalSpinner>;
};

Venue.propTypes = {
  venue: VenueModel,
  venueLoading: PropTypes.bool
};

export default Venue;
