import {useParams} from 'react-router-dom';
import useEvent from '../../../../hooks/useEvent';
import useSlideshow from '../../../../hooks/useSlideshow';

import useTeam from '../../../../hooks/useTeam';
import {ConditionalSpinner} from '../../../../molecules';

const Team = () => {
  const {id: eventId} = useParams();

  const {teamId} = useParams();
  const {team, teamLoading} = useTeam(teamId);

  let slideshow;

  const {event} = useEvent(eventId);

  if (event) {
    const {slideshow: newSlideshow} = useSlideshow((event?.slideshowId) || '');
    slideshow = newSlideshow;
  }

  return (
    <ConditionalSpinner showSpinnerWhen={teamLoading}>
      {team && <>
        <h2>{team.name}</h2>
        <p>{JSON.stringify(team.scores)}</p>
        <p>{JSON.stringify(slideshow)}</p>
      </>}
    </ConditionalSpinner>
  );
};

export default Team;
