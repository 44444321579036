import siteNavLinks from '../../config/siteNavLinks';
import SiteNavLink from '../siteNavLink/siteNavLink';

const SiteFooter = () => {
  return <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
    <p className="col-md-4 mb-0 text-muted">&copy; 2021-2022 Ag9 Software, Inc</p>

    <ul className="nav col-md-6 justify-content-end">
      {siteNavLinks?.map((link, index) => (
        <SiteNavLink
          key={`footer-site-nav-links-${index}`}
          link={link}
          linkClasses={'px-2 text-muted'}
        />
      ))}
    </ul>
  </footer>;
};

export default SiteFooter;
