import {addDoc, collection, getFirestore} from 'firebase/firestore';
import {useCollection} from 'react-firebase-hooks/firestore';

import {firebaseApp} from '../firebaseApp';

const usePrebuiltRounds = () => {
  const [prebuiltRounds, loading, error] = useCollection(
    collection(getFirestore(firebaseApp), 'prebuiltRounds'),
    {
      snapshotListenOptions: {includeMetadataChanges: true}
    }
  );

  const addPrebuiltRound = async newValue => {
    return await addDoc(collection(getFirestore(firebaseApp), 'prebuiltRounds'), newValue);
  };

  return {
    addPrebuiltRound,
    error,
    loading,
    prebuiltRounds: prebuiltRounds?.docs
  };
};

export default usePrebuiltRounds;
