import {ToggleButton, ToggleButtonGroup} from '@mui/material';
import {IconHeart, IconPlus} from '@tabler/icons-react';
import PropTypes from 'prop-types';

import {H2} from '../../../../atoms';
import {ConditionalTable, Slider, TextField, ThemedFAB} from '../../../../molecules';
import noop from '../../../../utils/noop';
import TagInput from '../../../tagInput/tagInput';

const Questions = ({
  buttonFilters,
  buttonFiltersChanged,
  changeHandler,
  difficultyRange,
  filterText,
  goToNewQuestion,
  handleDifficultyChange,
  isSuperAdmin,
  loading,
  onChangeFilterText,
  pageChanged,
  pageCount,
  pageSize,
  questionColumns,
  questions
}) => {
  return <div className={'pb-[40px]'}>
    <H2>Questions</H2>
    <form>
      <div className={'flex flex-col w-full p-2'}>
        <div className={'p-2 mb-4'}>
          <TagInput onChange={changeHandler}/>
        </div>
        <div
          className={'flex w-full mb-4'}>
          <div
            className={'mr-2'}>
            <TextField
              label={'Filter Text'}
              onChange={onChangeFilterText}
              value={filterText}/>
          </div>
          <div>
            <ToggleButtonGroup
              aria-label="text formatting"
              className={'mr-4'}
              onChange={buttonFiltersChanged}
              value={buttonFilters}
            >
              <ToggleButton
                value="favorites">
                <IconHeart className={'fill-red-500 stroke-red-500'}/>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className={'w-[300px]'}>
            <Slider
              max={5}
              min={1}
              onChange={handleDifficultyChange}
              values={difficultyRange}
            />
          </div>
        </div>
      </div>
    </form>
    <br/>
    <ConditionalTable
      columns={questionColumns}
      data={questions}
      loading={loading}
      pageChanged={pageChanged}
      pageCount={pageCount}
      pageSize={pageSize}
    />
    <br/>
    {isSuperAdmin && <ThemedFAB
      onClick={goToNewQuestion(history)}
      theme="primary">
      <IconPlus/>
    </ThemedFAB>}
  </div>;
};

Questions.propTypes = {
  buttonFilters: PropTypes.array,
  buttonFiltersChanged: PropTypes.func,
  changeHandler: PropTypes.func,
  difficultyRange: PropTypes.array,
  filterText: PropTypes.string,
  goToNewQuestion: PropTypes.func,
  handleDifficultyChange: PropTypes.func,
  isSuperAdmin: PropTypes.bool,
  loading: PropTypes.bool,
  onChangeFilterText: PropTypes.func,
  pageChanged: PropTypes.func,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  questionColumns: PropTypes.array,
  questions: PropTypes.array
};

Questions.defaultProps = {
  buttonFilters: [],
  buttonFiltersChanged: noop,
  changeHandler: noop,
  difficultyRange: [],
  filterText: '',
  goToNewQuestion: noop,
  handleDifficultyChange: noop,
  isSuperAdmin: false,
  loading: false,
  onChangeFilterText: noop,
  pageCount: 0,
  pageSize: 0,
  questionColumns: [],
  questions: []
};

export default Questions;
