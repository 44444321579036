import constants from '../../../../constants/constants';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {LoadCanvasTemplateNoReload, loadCaptchaEnginge, validateCaptcha} from 'react-simple-captcha';

const ContactSection = () => {
  const [captchaValue, setCaptchaValue] = useState('');
  const {register, handleSubmit } = useForm();

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  const handleContactClicked = data => {
    if (!validateCaptcha(captchaValue, false)) {
      return;
    }

    // TODO: do something with the data
    console.log(data);
  };

  const captchaInputChanged = event => {
    setCaptchaValue(event.target.value);
  };

  return (
    <div className="container col-xl-10 col-xxl-8 px-4 py-1">
      <div className="row align-items-center g-lg-5">
        <div className="col-lg-7 text-center text-lg-start">
          <h1 className="display-4 fw-bold lh-1 mb-3">Want To See TriviaMC In Action?</h1>
          <p className="col-lg-10 fs-4">
            Reach out to us and one of our representatives will reach out to you ASAP! We cannot wait to hear from you
            and show you what TriviaMC has to offer! We know you will love it as much as we do!
          </p>
        </div>
        <div className="col-md-10 mx-auto col-lg-5">
          <form
            className="p-4 p-md-5 border rounded-3 bg-light"
            onSubmit={handleSubmit(handleContactClicked)}>
            <div className="form-floating mb-3">
              <input
                className="form-control"
                id="floatingName"
                placeholder="Name"
                type="text"
                {...register('name', {maxLength: 50, required: true})}
              />
              <label htmlFor="floatingName">Name</label>
            </div>
            <div className="form-floating mb-3">
              <input
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                type="email"
                {...register('email', {maxLength: 50, pattern: constants.emailRegex, required: true})}
              />
              <label htmlFor="floatingInput">Email address</label>
            </div>
            <LoadCanvasTemplateNoReload/>
            <div className="form-floating mb-3">
              <input
                className="form-control"
                id="floatingName"
                onChange={captchaInputChanged}
                placeholder="Captcha"
                type="text"
                value={captchaValue}/>
              <label htmlFor="floatingName">Captcha</label>
            </div>
            <button
              className="w-100 btn btn-lg btn-primary"
              type="submit">Contact Us
            </button>
            <hr className="my-4"/>
            <small className="text-muted">
              By clicking &quot;Contact Us&quot;, you agree to allow a representative from TriviaMC to contact you.
            </small>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
