import Answer from '../../../slideTemplates/answer';
import FirestoreQuestion from '../../../slideTemplates/firestoreQuestion';
import Question from '../../../slideTemplates/question';
import Title from '../../../slideTemplates/title';

const mappings = {
  'answer': Answer,
  'firestoreQuestion': FirestoreQuestion,
  'question': Question,
  'title': Title
};

export default mappings;