import {FAB} from '../../atoms';
import ChildrenModel from '../../models/Children';
import ThemeModel from '../../models/Theme';
import themes from '../../theme/theme';

const ThemedFAB = ({children, theme, ...rest}) => {
  return <FAB
    color={themes[theme].color}
    hoverColor={themes[theme].hoverColor}
    iconColor={themes[theme].textColor}
    {...rest}>{children}</FAB>;
};

ThemedFAB.propTypes = {
  children: ChildrenModel,
  theme: ThemeModel
};

ThemedFAB.defaultProps = {
  theme: 'default'
};

export default ThemedFAB;
