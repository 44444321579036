import {IconCalendarPlus, IconPencil, IconPlayerPlay, IconPlayerStopFilled, IconSlideshow} from '@tabler/icons-react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';

import hardcodes from '../../../../config/hardcodes';
import {ConditionalTable, ThemedFAB, ThemedTooltipIconButton} from '../../../../molecules';
import SmallCalendar from '../../../../molecules/smallCalendar/smallCalendar';
import ConfirmationDialog from '../../../confirmationDialog/confirmationDialog';

const eventColumns = [{
  Header: 'Name',
  accessor: 'name'
}, {
  Header: 'Event Start',
  accessor: 'startDtm'
}, {
  Header: 'Event End',
  accessor: 'endDtm'
}, {
  Header: 'Actions',
  accessor: 'actions'
}];

const Events = ({events, loading, setUser, user}) => {
  const [stopConfirmationOpen, setStopConfirmationOpen] = useState(false);
  const {date} = useParams();

  const history = useHistory();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [slideshowToActivate, setSlideshowToActivate] = useState('');
  const [eventToActivate, setEventToActivate] = useState('');

  const showStopConfirmation = () => {
    setStopConfirmationOpen(true);
  };

  const openSlideshowTab = eventId => () => {
    window.open(`/tools/live/${eventId}`);
  };

  const handleStopConfirmationDialogClose = stop => {
    if (stop) {
      updateUserSlideshowAndEvent(null, null);
    }

    setStopConfirmationOpen(false);
  };
  const goToNewEvent = e => {
    e.preventDefault();

    history.push('/app/events/new');
  };

  const activateSlideshowAndEvent = (slideshowId, eventId) => () => {
    setConfirmationOpen(true);
    setSlideshowToActivate(slideshowId);
    setEventToActivate(eventId);
  };

  const handleConfirmationDialogClose = save => {
    if (save) {
      updateUserSlideshowAndEvent(slideshowToActivate, eventToActivate);
    }

    setConfirmationOpen(false);
  };

  function updateUserSlideshowAndEvent(slideshowId, eventId) {
    user.activeSlideshow = slideshowId;
    user.activeEvent = eventId;

    setUser(user, hardcodes.userId);
  }

  const goToDate = date => {
    history.push(`/app/events/date/${dayjs(date).format('YYYY-MM-DD')}`);
  };

  const editEvent = eventId => () => history.push(`/app/events/${eventId}`);

  const isActiveEvent = (activeEvent, eventId) => activeEvent === eventId;

  const getMappedEvents = activeEvent => events?.map(event => ({
    actions: <div className={'flex'}>
      <ThemedTooltipIconButton
        onClick={editEvent(event?.id)}
        text={'Edit Event'}
      >
        <IconPencil strokeWidth={1}/>
      </ThemedTooltipIconButton>
      {!isActiveEvent(activeEvent, event?.id) && <ThemedTooltipIconButton
        onClick={activateSlideshowAndEvent(event.slideshowId, event?.id)}
        text={'Start Event'}>
        <IconPlayerPlay strokeWidth={1}/>
      </ThemedTooltipIconButton>}
      {isActiveEvent(activeEvent, event?.id) && (
        <>
          <ThemedTooltipIconButton
            onClick={showStopConfirmation}
            text={'Stop Event'}>
            <IconPlayerStopFilled strokeWidth={1}/>
          </ThemedTooltipIconButton>
          <ThemedTooltipIconButton
            onClick={openSlideshowTab(event?.id)}
            text={'Show Slideshow'}>
            <IconSlideshow strokeWidth={1}/>
          </ThemedTooltipIconButton>
        </>
      )}
    </div>,
    endDtm: dayjs(event?.data()?.endDtm?.toDate()).format('M-DD-YYYY h:mm a'),
    name: <>
      {user?.activeEvent === event?.id && <IconPlayerPlay className={'inline'}/>}
      {event?.data()?.name}
    </>,
    startDtm: dayjs(event?.data()?.startDtm?.toDate()).format('M-DD-YYYY h:mm a')
  }));

  return <div className={'flex'}>
    <div className={'flex-grow mr-4'}>
      <ConditionalTable
        columns={eventColumns}
        data={getMappedEvents(user?.activeEvent)}
        dependencies={[user?.activeEvent]}
        loading={loading}/>
      <ConfirmationDialog
        message={'Would you like to start this event?'}
        onClose={handleConfirmationDialogClose}
        open={confirmationOpen}/>
      <ConfirmationDialog
        message={'Are you sure you want to stop the event?'}
        onClose={handleStopConfirmationDialogClose}
        open={stopConfirmationOpen}/>
    </div>
    <div className={'flex flex-col'}>
      <SmallCalendar
        onClick={goToDate}
        selectedDate={dayjs(date)}/>
    </div>
    <ThemedFAB
      onClick={goToNewEvent}
      style={{
        position: 'absolute',
        right: '40px',
        top: '80px'
      }}
      theme="secondary">
      <IconCalendarPlus strokeWidth={1}/>
    </ThemedFAB>
  </div>;
};

Events.propTypes = {
  events: PropTypes.array,
  loading: PropTypes.bool,
  setUser: PropTypes.func,
  user: PropTypes.shape({
    activeEvent: PropTypes.string,
    activeSlideshow: PropTypes.string
  })
};

Events.defaultProps = {
  loading: false
};

export default Events;
