import {IconPlayerSkipBack, IconPlayerSkipForward} from '@tabler/icons-react';
import * as PropTypes from 'prop-types';
import hardcodes from '../../../../config/hardcodes';
import useEvent from '../../../../hooks/useEvent';
import useSlideshow from '../../../../hooks/useSlideshow';
import BoardSlideshowRenderer from '../../../boardSlideshowRenderer/boardSlideshowRenderer';
import SlideshowControlButton from './slideshowControlButton';

const SlideshowControls = ({user}) => {
  const {event, setEvent} = useEvent(hardcodes.eventId);
  const {nextSlide, previousSlide} = useSlideshow(hardcodes.slideshowId);

  return <div className={'flex bg-white bottom-0 flex-row-reverse left-0 max-h-[120px] p-4 pl-12 ml-[60px] fixed right-0'}>
    {user && !user.activeSlideshow && (
      <div className={'flex'}>
        <div className={'text-center w-full'}>
          You do not have an active slideshow.
          When you do, the current slide and controls will appear here.
          Got to an event page in order to start a slideshow. <a href={'/app/events'}>Click Here.</a></div>
      </div>
    )}
    {user?.activeSlideshow && (
      <>
        <SlideshowControlButton onClick={previousSlide}>
          <IconPlayerSkipForward/>
        </SlideshowControlButton>
        <div className={'flex-grow-1 h-[100px] mx-2'}>
          <BoardSlideshowRenderer
            event={event}
            setEvent={setEvent}
            slideshowId={user.activeSlideshow}
            small/>
        </div>
        <SlideshowControlButton
          onClick={nextSlide}
        >
          <IconPlayerSkipBack/>
        </SlideshowControlButton>
      </>
    )}
  </div>;
};

SlideshowControls.propTypes = {
  company: PropTypes.shape({
    activeSlideshow: PropTypes.string
  }),
  currentSlide: PropTypes.any,
  goToPreviousSlide: PropTypes.func,
  onClick: PropTypes.func,
  user: PropTypes.shape({
    activeSlideshow: PropTypes.string
  })
};

export default SlideshowControls;
