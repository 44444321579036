import {collection, getFirestore, query} from 'firebase/firestore';
import {useCollection} from 'react-firebase-hooks/firestore';
import {firebaseApp} from '../firebaseApp';

const useTriviaCollection = (path, queries = []) => useCollection(
  query(collection(getFirestore(firebaseApp), path), ...queries),
  {
    snapshotListenOptions: { includeMetadataChanges: true }
  }
);

export default useTriviaCollection;
