import {createTheme, CssBaseline, ThemeProvider} from '@mui/material';
import * as React from 'react';

import adminNavLinks from '../../../../config/adminNavLinks';
import ChildrenModel from '../../../../models/Children';
import {AppBar} from '../../../../molecules';
import CommonAdminDrawer from '../../../commonAdminDrawer/commonAdminDrawer';

const mdTheme = createTheme();

const AdminWrapper = props => {
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <div className={'flex w-full h-full'}>
        <CssBaseline/>
        <AppBar text={'Trivia MC Admin'}/>
        <CommonAdminDrawer
          links={adminNavLinks}
          open={drawerOpen}
          toggleDrawer={toggleDrawer}
        />
        <main className={'grow-1 w-full p-4 ml-[60px] mt-[56px] b-0 r-0'}>
          {props.children}
        </main>
      </div>
    </ThemeProvider>
  );
};

AdminWrapper.propTypes = {
  children: ChildrenModel
};

export default AdminWrapper;
