import PropTypes from 'prop-types';
import ChildrenModel from '../../models/Children';

const H5 = ({children, className}) => <h5 className={`text-2xl font-thin ${className}`}>{children}</h5>;

H5.propTypes = {
  children: ChildrenModel,
  className: PropTypes.string
};

H5.defaultProps = {
  className: ''
};

export default H5;
