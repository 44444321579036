import {useParams} from 'react-router-dom';
import useCompany from '../../../../hooks/useCompany';
import useCompanyUsers from '../../../../hooks/useCompanyUsers';
import Company from './company';

// TODO: Look into lazy loading *All* images using a component like: https://github.com/Aljullu/react-lazy-load-image-component
const FirestoreCompany = () => {
  const {companyId} = useParams();
  const {company, companyLoading} = useCompany(companyId);
  const {companyUsers} = useCompanyUsers(companyId);

  return <Company
    company={company}
    companyUsers={companyUsers}
    loading={companyLoading}/>;
};


export default FirestoreCompany;
