import PropTypes from 'prop-types';
import {CustomPlaceholder} from 'react-placeholder-image';

import {H2} from '../../../../atoms';
import HostModel from '../../../../models/Host';
import {ConditionalSpinner} from '../../../../molecules';
import Address from '../../admin/company/address';
import CompanyCard from '../../admin/company/companyCard';

const Host = ({host, hostLoading}) => {
  return <ConditionalSpinner showSpinnerWhen={hostLoading}>
    <div className={'flex flex-row flex-wrap'}>
      <div className={'flex flex-col md:w-1/3 p-1'}>
        <CustomPlaceholder
          height={200}
          width={200}/>
        <CompanyCard heading={'Address'}>
          <Address address={host?.address}/>
        </CompanyCard>
      </div>
      <div className={'flex flex-col md:w-2/3 p-1'}>
        <H2>{host?.name}</H2>
        <CompanyCard
          heading={'E-mail'}
        >
          <div>
            Contact E-mail: {host?.contactEmail}
          </div>
        </CompanyCard>
      </div>
    </div>
  </ConditionalSpinner>;
};

Host.propTypes = {
  host: HostModel,
  hostLoading: PropTypes.bool
};

export default Host;
