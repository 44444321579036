import dayjs from 'dayjs';
import {Link, useParams} from 'react-router-dom';
import {H3, H4, H5, HR} from '../../../../atoms';
import useCompanyUsers from '../../../../hooks/useCompanyUsers';

import useEvent from '../../../../hooks/useEvent';
import useEventTeams from '../../../../hooks/useEventTeams';
import useSlideshowsCollection from '../../../../hooks/useSlideshowsCollection';
import useVenues from '../../../../hooks/useVenues';
import {ConditionalSpinner, ThemedAvatar} from '../../../../molecules';
import {Select} from '../../../../proteins';
import getInitials from '../../../../utils/getInitials';

/*
TODO: restyle

https://dribbble.com/shots/2459238-Event-Management-Dashboard-Wireframe-1/attachments/479651?mode=media

add counter: https://i.pinimg.com/originals/c2/9a/b3/c29ab38b9c0370e8b4a5b22b0755ce56.png

https://dribbble.com/shots/2469647-Event-Management-Dashboard-Wireframe-2/attachments/482765?mode=media
 */
export default function Event() {
  const {id: eventId} = useParams();

  // eslint-disable-next-line no-unused-vars
  const {event, eventLoading, setEvent} = useEvent(eventId);

  const {slideshowCollection, loading} = useSlideshowsCollection(null);
  const {eventTeams, eventTeamsLoading} = useEventTeams(eventId);
  const {venues, venuesLoading} = useVenues();
  const {companyUsers, companyUsersLoading} = useCompanyUsers();

  const hosts = [];

  const slideshowChanged = e => {
    event.slideshowId = e;
    setEvent(event, eventId);
  };

  companyUsers?.forEach(host => {
    if (event.hosts?.indexOf(host.id) > -1) {
      hosts.push(host.data().name);
    }
  });

  return <ConditionalSpinner showSpinnerWhen={eventLoading}>
    <H3>{event?.name}</H3>
    <div className={'flex flex-col'}>
      <H4 className={'mb-4'}>
        From {event?.startDtm && dayjs(event.startDtm.toDate()).format('h:mm')}&nbsp;
        to {event?.endDtm && dayjs(event.endDtm.toDate()).format('h:mm')}
      </H4>
      <HR/>
      <H5>Hosts</H5>
      <ConditionalSpinner showSpinnerWhen={companyUsersLoading}>
        <div className={'flex flex-col mb-4'}>
          {hosts.map((host, index) => <div
            className={'flex items-center'}
            key={`host-${index}`}>
            <ThemedAvatar theme={'primary'}>{getInitials(host)}</ThemedAvatar>
            <div className={'ml-4'}>{host}</div>
          </div>)}
        </div>
      </ConditionalSpinner>
      <HR/>
      <H5>Slideshow</H5>
      <Select
        className={'mb-4'}
        loading={loading}
        onChange={slideshowChanged}
        options={slideshowCollection?.map(slideshow => ({
          label: slideshow.data().name,
          value: slideshow.id
        }))}
        theme={'primary'}
        value={event.slideshowId}
      />
      <HR/>
      <H5>Venue</H5>
      <Select
        className={'mb-4'}
        loading={venuesLoading}
        options={venues?.map(venue => ({
          label: venue.data().name,
          value: venue.id
        }))}
        theme={'primary'}
        value={event.venueId}
      />
      <HR className={'mb-4'}/>
      <H5>
        <Link to={`/app/events/${eventId}/teams`}>Teams:</Link>
      </H5>
      <ConditionalSpinner showSpinnerWhen={eventTeamsLoading}>
        {eventTeams?.map(eventTeam => (
          <div key={eventTeam.data().name}>{eventTeam.data().name}</div>
        ))}
      </ConditionalSpinner>
    </div>
  </ConditionalSpinner>;
}
