import PropTypes from 'prop-types';
import ConditionalSpinner from '../conditionalSpinner/conditionalSpinner';
import Table from '../table/table';

const ConditionalTable = ({columns, data, dependencies, loading, pageChanged, pageCount, pageSize}) => {
  return <ConditionalSpinner showSpinnerWhen={loading}>
    {data && <Table
      columns={columns}
      data={data}
      dependencies={dependencies}
      pageChanged={pageChanged}
      pageCount={pageCount}
      pageSize={pageSize}
    />
    }
  </ConditionalSpinner>;
};

ConditionalTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  dependencies: PropTypes.array,
  loading: PropTypes.bool,
  pageChanged: PropTypes.func,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number
};

export default ConditionalTable;
