import PropTypes from 'prop-types';
import useQuestion from '../../hooks/useQuestion';
import BoardSlideshowAnswerSlide from './boardSlideshowAnswerSlide';

const FirestoreBoardSlideshowAnswerSlide = ({slide, round, questionId, small}) => {
  const {question} = useQuestion(questionId);

  return <BoardSlideshowAnswerSlide
    answers={question?.answers}
    question={slide}
    round={round}
    small={small}
    source={question?.source}
    text={question?.text} />;
};

FirestoreBoardSlideshowAnswerSlide.propTypes = {
  questionId: PropTypes.string,
  round: PropTypes.number,
  slide: PropTypes.number,
  small: PropTypes.bool
};

export default FirestoreBoardSlideshowAnswerSlide;
