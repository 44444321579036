import {initializeApp} from 'firebase/app';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import {enableIndexedDbPersistence, getFirestore} from 'firebase/firestore';

import firebaseConfig from './config/firebase';

export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);

onAuthStateChanged(auth, user => {
  // Check for user status
  if (user) {
    console.log(`user: ${user}`);
  }
});

enableIndexedDbPersistence(getFirestore(firebaseApp), {experimentalTabSynchronization: true})
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a time.
      // ...
    } else if (err.code === 'unimplemented') {
      // The current browser does not support all the
      // features required to enable persistence
      // ...
    }
  });

// getAnalytics();
