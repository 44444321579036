import {doc, getFirestore, setDoc} from 'firebase/firestore';

import {firebaseApp} from '../firebaseApp';
import useTriviaDocument from './useTriviaDocument';

const useCommonSettings = settingName => {
  const [document, loading, error] = useTriviaDocument('common', settingName);

  const setting = document ? document.data() : {};

  const saveSetting = async newValue => {
    return await setDoc(doc(getFirestore(firebaseApp), 'common', settingName), newValue);
  };

  return {
    saveSetting,
    setting,
    settingError: error,
    settingLoading: loading
  };
};

export default useCommonSettings;
