import {IconInfoCircle} from '@tabler/icons-react';

export const themeNames = [
  'default',
  'error',
  'primary',
  'secondary',
  'success',
  'warning'
];

const themes = {
  default: {
    avatarBackgroundColor: 'bg-gray-200',
    borderColor: 'border-gray-400',
    color: 'bg-none',
    headingColor: 'text-gray-500',
    hoverColor: 'hover:bg-gray-200',
    icon: IconInfoCircle,
    notificationBackgroundColor: 'bg-gray-50',
    notificationColor: 'text-gray-500',
    spinnerColor: 'text-black',
    textColor: 'text-black'
  },
  error: {
    avatarBackgroundColor: 'bg-rose-500',
    borderColor: 'border-rose-400',
    color: 'bg-rose-500',
    headingColor: 'text-rose-500',
    hoverColor: 'hover:bg-rose-700',
    icon: '',
    notificationBackgroundColor: 'bg-rose-50',
    notificationColor: 'text-rose-500',
    spinnerColor: 'text-rose-500',
    textColor: 'text-white'
  },
  info: {
    avatarBackgroundColor: 'bg-sky-500',
    borderColor: 'border-sky-400',
    color: 'bg-sky-500',
    headingColor: 'text-sky-500',
    hoverColor: 'hover:bg-sky-700',
    icon: IconInfoCircle,
    notificationBackgroundColor: 'bg-sky-50',
    notificationColor: 'text-sky-500',
    spinnerColor: 'text-sky-500',
    textColor: 'text-white'
  },
  primary: {
    avatarBackgroundColor: 'bg-sky-500',
    borderColor: 'border-sky-400',
    color: 'bg-sky-500',
    headingColor: 'text-sky-500',
    hoverColor: 'hover:bg-sky-700',
    icon: IconInfoCircle,
    notificationBackgroundColor: 'bg-sky-50',
    notificationColor: 'text-sky-500',
    spinnerColor: 'text-sky-500',
    textColor: 'text-white'
  },
  secondary: {
    avatarBackgroundColor: 'bg-rose-500',
    borderColor: 'border-rose-400',
    color: 'bg-rose-500',
    headingColor: 'text-rose-500',
    hoverColor: 'hover:bg-rose-700',
    icon: '',
    notificationBackgroundColor: 'bg-rose-50',
    notificationColor: 'text-rose-500',
    spinnerColor: 'text-rose-500',
    textColor: 'text-white'
  },
  success: {
    avatarBackgroundColor: 'bg-emerald-500',
    borderColor: 'border-emerald-400',
    color: 'bg-emerald-500',
    headingColor: 'text-emerald-500',
    hoverColor: 'hover:bg-emerald-700',
    icon: '',
    notificationBackgroundColor: 'bg-emerald-50',
    notificationColor: 'text-emerald-500',
    spinnerColor: 'text-emerald-500',
    textColor: 'text-white'
  },
  warning: {
    avatarBackgroundColor: 'bg-amber-500',
    borderColor: 'border-amber-400',
    color: 'bg-amber-500',
    headingColor: 'text-amber-500',
    hoverColor: 'hover:bg-amber-700',
    icon: '',
    notificationBackgroundColor: 'bg-amber-50',
    notificationColor: 'text-amber-500',
    spinnerColor: 'text-amber-500',
    textColor: 'text-black'
  }
};

export default themes;
