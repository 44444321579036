import ChildrenModel from '../../models/Children';

const H1 = ({children, ...props}) => <h1
  className={'text-6xl font-thin pb-4'}
  {...props}>{children}</h1>;

H1.propTypes = {
  children: ChildrenModel
};

export default H1;
