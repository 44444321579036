import PropTypes from 'prop-types';
import useQuestion from '../../hooks/useQuestion';
import BoardSlideshowQuestionSlide from './boardSlideshowQuestionSlide';

const FirestoreBoardSlideshowQuestionSlide = ({slide, round, questionId, small}) => {
  const {question} = useQuestion(questionId);

  return <BoardSlideshowQuestionSlide
    question={slide}
    round={round}
    small={small}
    text={question?.text} />;
};

FirestoreBoardSlideshowQuestionSlide.propTypes = {
  questionId: PropTypes.string,
  round: PropTypes.number,
  slide: PropTypes.number,
  small: PropTypes.bool
};

export default FirestoreBoardSlideshowQuestionSlide;
