import PropTypes from 'prop-types';
import RoundQuestionFooter from '../slideTemplates/roundQuestionFooter';
import SlideWrapper from '../slideTemplates/slideWrapper';

const BoardSlideshowQuestionSlide = ({question, round, small, text, ...rest}) => {
  return <SlideWrapper {...rest}>
    <div className={`flex flex-col justify-between h-full w-full ${!small && 'p-4 pt-[200]'} bg-sky-200`}>
      <div className={`${small ? 'text-2xl' : 'text-6xl'}`}>{text}</div>
      {!small && <RoundQuestionFooter
        question={question}
        round={round}/>}
    </div>
  </SlideWrapper>;
};

BoardSlideshowQuestionSlide.propTypes = {
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  round: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  small: PropTypes.bool,
  text: PropTypes.string
};

export default BoardSlideshowQuestionSlide;
