import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import {useState} from 'react';
import SmoothCollapse from 'react-smooth-collapse';
import {Paper} from '../../atoms';

import useSlideshow from '../../hooks/useSlideshow';
import {ConditionalSpinner} from '../../molecules';
import mappings from '../pages/app/slide/slideTemplateMappings';
import SlideTemplateRenderer from '../SlideTemplateRenderer/SlideTemplateRenderer';
import AddSlideTemplate from '../slideTemplates/addSlideTemplate';
import SlideEditor from './slideEditor';

const useStyles = makeStyles(() => ({
  editorCollapse: {
    flexGrow: '1'
  },
  linearSlideshow: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '50px',
    width: '100%'
  },
  root: {
    display: 'flex',
    height: '100%',
    width: '100%'
  },
  slide: {
    alignItems: 'center',
    display: 'flex',
    height: '240px',
    justifyContent: 'center',
    margin: '1em',
    width: '360px'
  },
  slideRow: {
    display: 'flex',
    overflowY: 'visible',
    width: '100%'
  }
}));

const FirestoreLinearSlideshow = props => {
  const classes = useStyles();
  const {slideshow, loading, setSlideshow} = useSlideshow(props.slideshowId);

  const [selectedSlideIndex, setSelectedSlideIndex] = useState(null);

  const newSlideClicked = () => {
    if (slideshow?.slides) {
      slideshow.slides.push({
        props: {
          title: 'Title'
        },
        template: 'title'
      });

      setSlideshow(slideshow, props.slideshowId);
    }
  };

  const slideClicked = index => {
    return () => {
      setSelectedSlideIndex(index);
    };
  };

  return (
    <div className={classes.root}>
      <div className={classes.linearSlideshow}>
        <ConditionalSpinner showSpinnerWhen={loading} />
        {slideshow?.slides?.map((slide, index) => {
          const Template = mappings[slide.template];

          return (
            <div
              className={classes.slideRow}
              key={`slide-${index}`}
            >
              <Paper
                className={'flex items-center justify-center h-[240px] w-[360px] m-1 p-0'}
                elevation={index === selectedSlideIndex ? 3 : 0}
              >
                <SlideTemplateRenderer
                  Template={Template}
                  onClick={slideClicked(index)}
                  templateProps={slide.props}
                />
              </Paper>
              <SmoothCollapse
                className={classes.editorCollapse}
                expanded={index === selectedSlideIndex}
                heightTransition={'0.3s linear'}
              >
                <SlideEditor
                  cancelButtonClicked={() => setSelectedSlideIndex(null)}
                  saveSlide={slide => {
                    slideshow.slides[index] = slide;
                    setSlideshow(slideshow, props.slideshowId);
                  }}
                  slide={slide}
                />
              </SmoothCollapse>
            </div>
          );
        })}
        <Paper
          className={classes.slide}
          elevation={5}
          key={'slide-new'}
        >
          <SlideTemplateRenderer
            Template={AddSlideTemplate}
            onClick={newSlideClicked}
            templateProps={{}}
          />
        </Paper>
      </div>
    </div>
  );
};

FirestoreLinearSlideshow.propTypes = {
  slideshowId: PropTypes.string
};

export default FirestoreLinearSlideshow;
