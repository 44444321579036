import {useEffect, useState} from 'react';
import {Client} from 'typesense';

const useTypeSenseQuestions = (searchTerm = '', difficulty = [1, 5], page = 0, per_page = 10) => {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [searchResults, setSearchResults] = useState({});

  useEffect(() => {
    setLoading(true);

    const search = {
      filter_by: `difficulty:[${difficulty[0]}..${difficulty[1]}]`,
      page,
      per_page,
      q : searchTerm,
      query_by: 'text',
    };

    const client = new Client({
      'apiKey': 'Z6Fr9PZMpVzocqw7PbNbcX1MrtZSq0wW',
      'connectionTimeoutSeconds': 2,
      'nodes': [{
        'host': 'hj0r23eianx9u78qp-1.a1.typesense.net',
        'port': 443,
        'protocol': 'https'
      }]
    });

    client.collections('questions')
      .documents()
      .search(search)
      .then(function (searchResults) {
        setQuestions(searchResults.hits);
        setSearchResults(searchResults);
        setLoading(false);
      });
  }, [searchTerm, difficulty[0], difficulty[1], page, per_page]);

  return {
    loading,
    questions,
    searchResults
  };
};

export default useTypeSenseQuestions;
