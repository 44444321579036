import {TextField} from '@mui/material';
import PropTypes from 'prop-types';

const EditEventDateInput = ({fieldName, label, register}) => <TextField
  InputLabelProps={{
    shrink: true
  }}
  className={'w-full'}
  fullWidth
  label={label}
  type="datetime-local"
  {...register(fieldName, {required: true})}
/>;

EditEventDateInput.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.string,
  register: PropTypes.func
};

export default EditEventDateInput;
