import PropTypes from 'prop-types';

import ChildrenModel from '../../models/Children';

const H3 = ({children, className, ...rest}) => <h3
  className={`text-4xl font-thin ${className}`}
  {...rest}>{children}</h3>;

H3.propTypes = {
  children: ChildrenModel,
  className: PropTypes.string
};

H3.defaultProps = {
  className: ''
};

export default H3;
