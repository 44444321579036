import {IconPencil} from '@tabler/icons-react';
import {useRouteMatch} from 'react-router-dom';

import hardcodes from '../../../../config/hardcodes';
import useSlideshowsCollection from '../../../../hooks/useSlideshowsCollection';
import {ConditionalTable, ThemedTooltipIconButton} from '../../../../molecules';

const slideshowColumns = [{
  Header: 'Name',
  accessor: 'name'
}, {
  Header: 'Theme',
  accessor: 'theme'
}, {
  Header: 'Actions',
  accessor: 'actions'
}];

const Slideshows = () => {
  const {url} = useRouteMatch();
  const {slideshowCollection, loading} = useSlideshowsCollection(null, hardcodes.companyId);

  const getMappedSlideshows = () => slideshowCollection?.map(slideshow => ({
    actions: <ThemedTooltipIconButton
      onClick={() => {
        window.location = `${url}/${slideshow?.id}/${slideshow?.data()?.editorType}`;
      }}
      text={'Edit Slideshow'}>
      <IconPencil strokeWidth={1}/>
    </ThemedTooltipIconButton>,
    name: slideshow.data().name,
    theme: slideshow.data().theme
  }));

  return <ConditionalTable
    columns={slideshowColumns}
    data={getMappedSlideshows()}
    loading={loading}/>;
}
;

export default Slideshows;
