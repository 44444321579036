import PropTypes from 'prop-types';

import useQuestion from '../../hooks/useQuestion';
import Question from './question';

const FirestoreQuestion = props => {
  const {question} = useQuestion(props.questionId);
  return question && <Question
    question={props.question}
    round={props.round}
    text={question.data().text}/>;
};

FirestoreQuestion.propTypes = {
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  questionId: PropTypes.string,
  round: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default FirestoreQuestion;
