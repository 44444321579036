const triviaMCTeam = [{
  biography: 'Longer Biography is Coming Soon...',
  name: 'Tony',
  shortBiography: 'Coming Soon...'
}, {
  biography: 'Longer Biography is Coming Soon...',
  name: 'Deb',
  shortBiography: 'Coming Soon...'
}];

export default triviaMCTeam;
