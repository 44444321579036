import {IconDots} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import {ThemedTooltipIconButton} from '../../molecules';

const TextShortener = ({ending, length, onClickMore, text}) => {

  const isTextShorterThanLength = () => {
    return text.length < length;
  };

  const getText = () => {
    if (isTextShorterThanLength()) {
      return text;
    }

    return text.substring(0, length) + ending;
  };

  return (
    <div className={'flex justify-start items-center'}>
      {getText()}

      {!isTextShorterThanLength() && <ThemedTooltipIconButton
        onClick={onClickMore}
        text={`${text} - Click to view more.`}
        theme={'default'}>
        <IconDots/>
      </ThemedTooltipIconButton>}
    </div>
  );
};

TextShortener.propTypes = {
  ending: PropTypes.string,
  length: PropTypes.number,
  onClickMore: PropTypes.func,
  text: PropTypes.string
};

TextShortener.defaultProps = {
  ending: '...',
  text: ''
};

export default TextShortener;
