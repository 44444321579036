import hardcodes from '../config/hardcodes';
import useTriviaDocument from './useTriviaDocument';
import {COMPANY_PATH} from './useCompanies';

const useCompany = (companyId = hardcodes.companyId) => {
  const [document, loading, error] = useTriviaDocument(COMPANY_PATH, companyId);

  const company = document?.data();

  return {
    company,
    companyError: error,
    companyLoading: loading
  };
};

export default useCompany;
