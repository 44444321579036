import dayjs from 'dayjs';

const today = dayjs().startOf('day');
const tomorrow = today.add(1, 'day');

const constants = {
  emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  today,
  todayFilter: event => dayjs(event.data().startDtm.toDate()).isSame(today, 'day'),
  tomorrow,
  tomorrowFilter: event => dayjs(event.data().startDtm.toDate()).isSame(tomorrow, 'day')
};

export default constants;
