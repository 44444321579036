export const getRoundScore = (team, round) => {
  let score = 0;

  Object.keys(team.scores[round]).forEach(question => {
    score += team.scores[round][question].points;
  });

  return score;
};

export const getTeamTotalScore = team => {
  let score = 0;

  if (team) {
    Object.keys(team.scores).forEach(round => {
      score += getRoundScore(team, round);
    });
  }

  return score;
};

export const getTeamScores = team => {
  let scores = [];

  if (team && team.scores) {
    Object.keys(team.scores).forEach(round => {
      scores.push(getRoundScore(team, round));
    });
  }

  return scores;
};

export const getMaxRoundsFromTeams = teams => {
  let max = 0;

  if (teams && teams.length) {
    teams.forEach(team => {
      const rounds = Object.keys(team.scores).length;

      if (rounds > max) {
        max = rounds;
      }
    });
  }

  return max;
};
