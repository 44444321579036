import {Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme} from '@mui/material';
import PropTypes from 'prop-types';
import usePrebuiltRounds from '../../hooks/usePrebuiltRounds';
import {CloseButton, ThemedButton} from '../../molecules';
import {Select} from '../../proteins';

const PrebuiltRoundSelectionDialog = ({handleClose, open, roundId, setRoundId}) => {
  const {prebuiltRounds} = usePrebuiltRounds();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return <Dialog
    fullScreen={fullScreen}
    onClose={handleClose(false)}
    open={open}
  >
    <DialogTitle>
      <div className={'flex flex-row'}>
        <div className={'pr-1'}>Prebuilt Round Selection</div>
        <CloseButton onClick={handleClose(false)}/>
      </div>
    </DialogTitle>
    <DialogContent>
      {prebuiltRounds && <Select
        onChange={setRoundId}
        options={prebuiltRounds?.map(round => ({
          label: round.data().title,
          value: round.id
        }))}
        value={roundId}
      />}
      <DialogActions>
        <ThemedButton
          onClick={handleClose(false)}
          theme={'default'}>Cancel</ThemedButton>
        <ThemedButton
          onClick={handleClose(true)}
          theme={'secondary'}>Save</ThemedButton>
      </DialogActions>
    </DialogContent>
  </Dialog>;
};

PrebuiltRoundSelectionDialog.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  roundId: PropTypes.string,
  setRoundId: PropTypes.func
};

export default PrebuiltRoundSelectionDialog;
