import PropTypes from 'prop-types';
import AddressModel from './Address';

const Host = PropTypes.shape({
  address: AddressModel,
  contactEmail: PropTypes.string,
  name: PropTypes.string
});

export default Host;
