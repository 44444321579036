import {IconChevronLeft, IconChevronRight, IconChevronsLeft, IconChevronsRight} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import {useEffect, useMemo} from 'react';
import {usePagination, useTable} from 'react-table';
import {noop} from '../../utils';
import {ThemedTooltipIconButton} from '../index';

const pageSizeChoices = [25, 50];

const Table = ({columns, data, dependencies, pageCount: controlledPageCount, pageSize: controlledPageSize, pageChanged}) => {
  const memoColumns = useMemo(() => columns, dependencies);

  const memoRows = useMemo(() => data, dependencies);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns: memoColumns,
      data: memoRows,
      initialState: {
        pageSize: controlledPageSize
      },
      manualPagination: true,
      pageCount: controlledPageCount
    },
    usePagination
  );

  useEffect(() => {
    pageChanged(pageIndex + 1);
  }, [pageIndex]);

  // Render the UI for your table
  return <div className={'border-1 border-gray-200 rounded-sm shadow-md'}>
    <table
      className={'table-auto w-full'}
      {...getTableProps()}>
      <thead
        className={'border-b border-gray-200'}
      >
        {headerGroups?.map((headerGroup, headerGroupIndex) => (
          <tr
            className={'w-full'}
            key={`table-header-${headerGroupIndex}`}
            {...headerGroup?.getHeaderGroupProps()}>
            {headerGroup?.headers?.map((column, columnIndex) => (
              <th
                className={'flex-grow p-3'}
                key={`table-header-column-${columnIndex}`}
                {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page?.map((row, rowIndex) => {
          prepareRow(row);
          return (
            <tr
              className={'border-b border-1 border-gray-200'}
              key={`table-row-${rowIndex}`}
              {...row.getRowProps()}>
              {row.cells.map((cell, cellIndex) => {
                return <td
                  className={'px-3 py-2'}
                  key={`table-row-${rowIndex}-cell-${cellIndex}`}
                  {...cell.getCellProps()}>{cell.value}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
    <div className={'pagination flex justify-between items-center'}>
      <ThemedTooltipIconButton
        disabled={!canPreviousPage}
        onClick={() => gotoPage(0)}
        text={'First Page'}>
        <IconChevronsLeft strokeWidth={1}/>
      </ThemedTooltipIconButton>
      <ThemedTooltipIconButton
        disabled={!canPreviousPage}
        onClick={() => previousPage()}
        text={'Previous Page'}>
        <IconChevronLeft strokeWidth={1} />
      </ThemedTooltipIconButton>
      <div>
        <span>
          Page
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:
          <input
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '100px' }}
            type="number"
          />
        </span>
        <select
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          value={pageSize}
        >
          {pageSizeChoices.map(pageSize => (
            <option
              key={pageSize}
              value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <ThemedTooltipIconButton
        disabled={!canNextPage}
        onClick={() => nextPage()}
        text={'Next Page'}>
        <IconChevronRight strokeWidth={1} />
      </ThemedTooltipIconButton>
      <ThemedTooltipIconButton
        disabled={!canNextPage}
        onClick={() => gotoPage(pageCount -1)}
        text={'Last Page'}>
        <IconChevronsRight strokeWidth={1} />
      </ThemedTooltipIconButton>
    </div>
  </div>;
};

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  dependencies: PropTypes.array,
  pageChanged: PropTypes.func,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number
};

Table.defaultProps = {
  columns: [],
  data: [],
  dependencies: [],
  pageChanged: noop,
  pageCount: 0,
  pageSize: 0
};

export default Table;
