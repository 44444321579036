import {doc, getFirestore, setDoc} from 'firebase/firestore';

import {firebaseApp} from '../firebaseApp';
import useTriviaDocument from './useTriviaDocument';

const useQuestion = questionId => {
  const [document, loading, error] = useTriviaDocument('questions', questionId);

  const question = document?.data();

  const saveQuestion = async newValue => {
    return await setDoc(doc(getFirestore(firebaseApp), 'questions', questionId), {...newValue, difficulty: parseInt(newValue.difficulty)});
  };

  return {
    question,
    questionError: error,
    questionLoading: loading,
    saveQuestion
  };
};

export default useQuestion;
