import AddressModel from '../../../../models/Address';

const Address = ({address}) => {
  return <div className={'flex'}>
    <div>
      {address?.address1}
    </div>
    <div>
      {address?.address2}
    </div>
    <div>
      {address?.city}, {address?.state}, {address?.zipcode}
    </div>
    <div>
      {address?.phone}
    </div>
  </div>;
};

Address.propTypes = {
  address: AddressModel
};

Address.defaultProps = {
  address: {}
};

export default Address;
