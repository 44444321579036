import {IconPlus} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {useDialog} from 'react-dialog-hook';

import {H2, IconButton} from '../../atoms';
import {ConditionalSpinner} from '../../molecules';
import {BoardRound} from '../../proteins';
import {addRoundToSlideshow} from '../../utils/boardSlideshows';
import {addSlideToRound, deleteRound, deleteSlideFromRound, saveRound, saveSlideInRound} from '../../utils/rounds';
import PrebuiltRoundSelectionDialog from './prebuiltRoundSelectionDialog';
import QuestionSelectionDialog from './questionSelectionDialog';
import RenameRoundDialog from './renameRoundDialog';

const BoardSlideshow = ({loading, setSlideshow, slideshow, slideshowId}) => {
  const [renameRoundDialogOpen, setRenameRoundDialogOpen] = useState(false);
  const [questionSelectionDialogOpen, setQuestionSelectionDialogOpen] = useState(false);
  const [prebuiltRoundSelectionDialogOpen, setPrebuiltRoundSelectionDialogOpen] = useState(false);
  const [roundIndexToEdit, setRoundIndexToEdit] = useState();
  const [roundToEdit, setRoundToEdit] = useState({});
  const [tempPrebuiltRoundId, setTempPrebuiltRoundId] = useState('');
  const {close, isOpen, open} = useDialog();

  const renameRoundDialogClosed = save => () => {
    setRenameRoundDialogOpen(false);

    if (save) {
      console.log(slideshow.round[roundIndexToEdit]);
    }
  };

  const showRenameRoundDialog = (round, roundIndex) => {
    setRenameRoundDialogOpen(true);
    setRoundIndexToEdit(roundIndex);
    setRoundToEdit(round);
  };

  const showQuestionSelectionDialog = () => {
    setQuestionSelectionDialogOpen(true);
  };

  const closeQuestionSelectionDialog = () => {
    setQuestionSelectionDialogOpen(false);
  };

  const showPrebuiltRoundSelectionDialog = (prebuiltRoundId, roundIndex) => () => {
    setRoundIndexToEdit(roundIndex);
    setTempPrebuiltRoundId(prebuiltRoundId);
    setPrebuiltRoundSelectionDialogOpen(true);
  };

  const closePrebuiltRoundSelectionDialog = (save, roundId) => () => {
    close(tempPrebuiltRoundId);
    setPrebuiltRoundSelectionDialogOpen(false);

    if (save && !roundId) {
      // slideshow.round.push({
      //   number: slideshow.round.length,
      //   prebuiltRoundId: tempPrebuiltRoundId,
      //   slides: []
      // });

      // slideshow.rounds = slideshow.round.length;
      //
      // setSlideshow(slideshow, slideshowId);
    }
  };

  const newRoundClicked = async () => {

    const dialogParams = 'PARAM';
    const prebuiltRoundId = await open(dialogParams); // It returns results passed as argument to close method

    addRoundToSlideshow(slideshow, setSlideshow, slideshowId)(prebuiltRoundId);
  };

  return <>
    <H2>Board Designer</H2>
    <div className={'pb-[160px]'}>
      <ConditionalSpinner showSpinnerWhen={loading}>
        <div className={'flex flex-row'}>
          {slideshow?.round?.map((round, index) => (
            round && <BoardRound
              addNewSlide={addSlideToRound(slideshow, setSlideshow, slideshowId)}
              deleteRound={deleteRound(slideshow, setSlideshow, slideshowId)}
              deleteSlide={deleteSlideFromRound(slideshow, setSlideshow, slideshowId)}
              index={index}
              key={`round-${index}`}
              round={{...round, number: round.number || index}}
              saveRound={saveRound(slideshow, setSlideshow, slideshowId)}
              saveSlide={saveSlideInRound(slideshow, setSlideshow, slideshowId)}
              showPrebuiltRoundSelectionDialog={showPrebuiltRoundSelectionDialog(round.prebuiltRoundId, index)}
              showQuestionSelectionDialog={showQuestionSelectionDialog}
              showRenameDialog={showRenameRoundDialog}
            />
          ))}
          <div className={'flex h-[40px]'}>
            <IconButton onClick={newRoundClicked}>
              <IconPlus/>
            </IconButton>
          </div>
        </div>
      </ConditionalSpinner>
      <RenameRoundDialog
        onClick={renameRoundDialogClosed(true)}
        onClose={renameRoundDialogClosed(false)}
        open={renameRoundDialogOpen}
        roundToEdit={roundToEdit}/>
      <QuestionSelectionDialog
        handleClose={closeQuestionSelectionDialog}
        open={questionSelectionDialogOpen}/>
      <PrebuiltRoundSelectionDialog
        handleClose={closePrebuiltRoundSelectionDialog}
        open={prebuiltRoundSelectionDialogOpen || isOpen}
        roundId={tempPrebuiltRoundId}
        setRoundId={setTempPrebuiltRoundId}
      />
    </div>
  </>;
};

BoardSlideshow.propTypes = {
  loading: PropTypes.bool,
  setSlideshow: PropTypes.func,
  slideshow: PropTypes.shape({
    round: PropTypes.array
  }),
  slideshowId: PropTypes.string
};

export default BoardSlideshow;
