const firebaseConfig = {
  apiKey: 'AIzaSyCytXuCPFDMKiokC3arp-pIGOuztzmu_PI',
  appId: '1:847348009125:web:d4aa62e188d5e70641654c',
  authDomain: 'trivia-f4922.firebaseapp.com',
  databaseURL: 'https://trivia-f4922.firebaseio.com',
  measurementId: 'G-J5NJ7BY1ZR',
  messagingSenderId: '847348009125',
  projectId: 'trivia-f4922',
  storageBucket: 'trivia-f4922.appspot.com'
};

export default firebaseConfig;
