import PropTypes from 'prop-types';

import {Tooltip} from '../../atoms';
import ChildrenModel from '../../models/Children';
import ThemeModel from '../../models/Theme';
import {ThemedIconButton} from '../index';

const ThemedTooltipIconButton = ({children, onClick, text, theme, ...rest}) => {
  return <Tooltip>
    <ThemedIconButton
      data-tip={text}
      onClick={onClick}
      theme={theme}
      {...rest}>{children}</ThemedIconButton>
  </Tooltip>;
};

ThemedTooltipIconButton.propTypes = {
  children: ChildrenModel,
  onClick: PropTypes.func,
  text: PropTypes.string,
  theme: ThemeModel
};

export default ThemedTooltipIconButton;
