import PropTypes from 'prop-types';

import {Button} from '../../atoms';
import ChildrenModel from '../../models/Children';
import ThemeModel from '../../models/Theme';
import getButtonClasses from '../../theme/getButtonClasses';
import {noop} from '../../utils';

const ThemedButton = ({children, className, onClick, theme, ...rest}) => <Button
  className={`${getButtonClasses(theme)} ${className}`}
  onClick={onClick}
  {...rest}>{children}</Button>;

ThemedButton.propTypes = {
  children: ChildrenModel,
  className: PropTypes.string,
  onClick: PropTypes.func,
  theme: ThemeModel
};

ThemedButton.defaultProps = {
  className: '',
  onClick: noop,
  theme: 'primary'
};

export default ThemedButton;
