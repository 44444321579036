import {where} from 'firebase/firestore';
import hardcodes from '../config/hardcodes';
import useTriviaCollection from './useTriviaCollection';

const useCompanyUsers = (companyId = hardcodes.companyId) => {
  const [companyUsers, loading, error] = useTriviaCollection('users', [where('companyId', '==', companyId)]);

  return {
    companyUsers: companyUsers ? companyUsers.docs : [],
    companyUsersError: error,
    companyUsersLoading: loading
  };
};

export default useCompanyUsers;
