import PropTypes from 'prop-types';

const Chip = ({className, color, text}) => {
  return <span className={`rounded-full p-2 text-xs text-white ${color} ${className}`}>{text}</span>;
};

Chip.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string
};

Chip.defaultProps = {
  color: 'bg-sky-500'
};

export default Chip;
