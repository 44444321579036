import PropTypes from 'prop-types';
import {Divider, Drawer, DrawerMenuItem} from '../../molecules';
import {noop} from '../../utils';

const CommonAdminDrawer = ({links}) => {
  return <Drawer>
    {links?.map((item, index) => (
      item.type === 'divider' ? <Divider key={`divider-${index}`}/> : <DrawerMenuItem
        href={item.href}
        icon={item.icon}
        key={item.text}
        text={item.text}
      />
    ))}
  </Drawer>;
};

CommonAdminDrawer.propTypes = {
  links: PropTypes.array,
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func
};

CommonAdminDrawer.defaultProps = {
  links: [],
  open: true,
  toggleDrawer: noop
};

export default CommonAdminDrawer;
