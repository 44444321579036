import {IconCaretDown, IconCaretUp, IconX} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import {useRef} from 'react';
import useSelect from 'use-select';
import ThemeModel from '../../models/Theme';
import {ConditionalSpinner} from '../../molecules';
import {noop} from '../../utils';

const Select = ({
  className,
  value,
  options,
  onChange,
  multi,
  placeholder,
  loading,
  theme
}) => {
  const optionsRef = useRef();

  // Use useSelect to manage select state
  const {
    visibleOptions,
    selectedOption,
    // highlightedOption,
    getInputProps,
    getOptionProps,
    isOpen,
    setOpen
  } = useSelect({
    multi,
    onChange,
    options,
    optionsRef,
    value
  });

  const toggleOpen = () => {
    setOpen(!isOpen);
  };
  //
  // const isHighlightedOption = option => highlightedOption === option;

  const isSelectedOption = option => selectedOption === option;

  const getIcon = () => {
    return <ConditionalSpinner
      showSpinnerWhen={loading}
      theme={theme}>{isOpen ? <IconCaretUp/> : <IconCaretDown/>}</ConditionalSpinner>;
  };

  // Build your select component
  return <div className={`outer ${className}`}>
    <div
      className={'flex border-1 border-gray-400 hover:border-gray-700 bg-white items-center rounded cursor-pointer px-2 py-3'}
      onClick={!multi && toggleOpen}>
      {multi ? (
        <div className={'multi mr-2 z-[150]'}>
          {selectedOption?.map(option => (
            <div
              className={'option flex flex-row rounded border-1 border-gray-400 p-2'}
              key={option.value}>
              <div className={'p-1'}>
                {option.value}{' '}
              </div>
              <div
                className={'flex items-center rounded hover:bg-gray-200 p-0.5'}
                onClick={e => {
                  e.preventDefault();

                  onChange(value.filter(d => d !== option.value));
                }}
              >
                <IconX
                  strokeWidth={1}/>
              </div>
            </div>
          ))}
        </div>
      ) : null}
      <input
        {...getInputProps()}
        className={'flex-grow bg-none focus:border-sky-700 p-1 placeholder:pl-2'}
        placeholder={isOpen ? 'Search...' : placeholder}/>
      <div>
        {getIcon()}
      </div>
    </div>
    <div>
      {isOpen ? (
        <div
          className={'results flex flex-col rounded ease-in duration-150 bg-white p-2 drop-shadow-md absolute z-[140]'}
          ref={optionsRef}>
          {!visibleOptions?.length ? (
            <div>No results...</div>
          ) : null}
          {visibleOptions?.map((option, index) => {
            return <div
              key={`option-${index}`}>
              <div
                {...getOptionProps({
                  className: `rounded hover:bg-gray-200 p-2 cursor-pointer ${isSelectedOption(option) && 'bg-sky-300 hover:bg-sky-200'}`,
                  index,
                  option
                })}
              >
                {option.label}
              </div>
            </div>;
          })}
        </div>
      ) : null}
    </div>
  </div>;
};

Select.propTypes = {
  className: PropTypes.string,
  itemHeight: PropTypes.number,
  loading: PropTypes.bool,
  multi: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })),
  pageSize: PropTypes.number,
  placeholder: PropTypes.string,
  theme: ThemeModel,
  value: PropTypes.any
};

Select.defaultProps = {
  itemHeight: 40,
  onChange: noop,
  pageSize: 10,
  placeholder: 'Select one...',
  theme: 'default'
};

export default Select;
