import {makeStyles} from '@mui/styles';
import clsx from 'clsx';


import '../../../../custom.scss';
import useCommonSettings from '../../../../hooks/useCommonSettings';
import usePrebuiltRounds from '../../../../hooks/usePrebuiltRounds';
import useQuestions from '../../../../hooks/useQuestions';
import SiteFooter from '../../../siteFooter/siteFooter';
import SiteHeader from '../../../siteHeader/siteHeader';
import ContactSection from './contactSection';
import './home.scss';
import HomeCountFeature from './homeCountFeature';
import HomeFeatureList from './homeFeatureList';
import manBlueShirtSittingImage from './man-blue-shirt-sitting-microphone.jpg';
import microphoneImage from './microphone.jpg';

const useStyles = makeStyles({
  bgMicrophone: {
    backgroundImage: `url(${microphoneImage})`,
    backgroundSize: 'cover'
  },
  counterText: {
    minWidth: '80px',
    textAlign: 'right',
    width: '80px'
  },
  cutOut: {
    borderRadius: '21px 21px 0 0',
    height: '100%',
    minHeight: '300px',
    width: '80%'
  },
  feature: {
    alignItems: 'center',
    display: 'inline-flex',
    fontSize: '2rem',
    height: '4rem',
    justifyContent: 'center',
    minWidth: '4rem',
    padding: '0 8px'
  },
  manSittingImage: {
    borderRadius: '12px'
  },
  textShadow: {
    textShadow: '1px 1px black'
  }
});

const HomePage = () => {
  const classes = useStyles();
  const {questions} = useQuestions();
  const {setting} = useCommonSettings('tags');
  const {prebuiltRounds} = usePrebuiltRounds();

  const tags = (setting?.values) ? setting.values : [];

  return <>
    <SiteHeader/>

    <main>
      <div
        className={clsx(classes.bgMicrophone, 'position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light')}>
        <div className="col-md-5 p-lg-5 mx-auto my-5">
          <h1 className={clsx(classes.textShadow, 'display-4 fw-normal text-light')}>Trivia MC</h1>
          <p className={clsx(classes.textShadow, 'lead fw-normal text-light')}>Everything you need to run your own
            trivia night!</p>
          <a
            className="btn btn-primary text-light"
            href="#">Learn More</a>
        </div>
      </div>

      <section className="pt-4">
        <div className="container px-lg-5">
          <div className="row gx-lg-5">
            <HomeCountFeature
              label={'Questions And Counting!'}
              text={'A full library of questions at your finger tips! No need to come up with your own questions. All ' +
                'of them also include a source link.'}
              values={questions}
            />
            <HomeCountFeature
              label={'Unique Themes!'}
              text={'Looking to host a Christmas or Halloween themed night? We\'ve got you covered.'}
              values={tags}
            />
            <HomeCountFeature
              label={'Prebuilt Rounds'}
              text={'Collections of questions ready for you to add to your Trivia night.'}
              values={prebuiltRounds}
            />
          </div>
        </div>
      </section>

      <div className="container col-xxl-8 px-4 py-1">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-10 col-sm-8 col-lg-6">
            <img
              alt="Device and Personality"
              className={clsx(classes.manSittingImage, 'd-block mx-lg-auto img-fluid')}
              height="500"
              loading="lazy"
              src={manBlueShirtSittingImage}
              width="700"/>
          </div>
          <div className="col-lg-6">
            <h1 className="display-5 fw-bold lh-1 mb-3">Just Bring Your Device And Personality...</h1>
            <p className="lead">
              ...We will handle the rest. Our web-first approach allows TriviaMC to be used on almost any web-capable
              device and as long as you are connected to the internet, you can use multiple devices together without any
              special hardware or adapters!
            </p>
            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
              <button
                className="btn btn-primary btn-lg px-4 me-md-2"
                type="button">Learn More
              </button>
              <button
                className="btn btn-outline-secondary btn-lg px-4"
                type="button">Request Demo
              </button>
            </div>
          </div>
        </div>
      </div>

      <HomeFeatureList/>
      <ContactSection/>
    </main>

    <SiteFooter/>
  </>;
};

export default HomePage;
