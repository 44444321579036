import {NotifierContextProvider} from 'react-headless-notifier';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Admin from './components/pages/admin';
import CustomerApp from './components/pages/app';
import CustomerAdminWrapper from './components/pages/app/customerAdminWrapper/customerAdminWrapper';
import Tools from './components/pages/app/tools';
import HomePage from './components/pages/site/home/home';
import PricingPage from './components/pages/site/pricing/pricingPage';
import Team from './components/pages/site/team/team';

// TODO: Do something like this: https://stackoverflow.com/a/54112771
export default function App() {
  return (
    <NotifierContextProvider
      config={{
        duration: 5000, // Duration by notification in milliseconds
        max: null, // Max number of notiication simultaneously, `null` will result in no maximum
        position: 'topRight', // Default position for all the notification if it's not specify when using `notify()`, valid positions are 'top', 'topRight', 'topLeft', 'bottomRight', 'bottomLeft', 'bottom'.
      }}
    >
      <Router>
        <Switch>
          <Route
            exact
            path="/">
            <HomePage/>
          </Route>
          <Route path="/pricing">
            <PricingPage/>
          </Route>
          <Route path="/team">
            <Team/>
          </Route>

          <Route path="/admin">
            <Admin/>
          </Route>
          <Route path="/app">
            <CustomerAdminWrapper>
              <CustomerApp/>
            </CustomerAdminWrapper>
          </Route>
          <Route path="/tools">
            <Tools/>
          </Route>
        </Switch>
      </Router>
    </NotifierContextProvider>
  );
}
