import PropTypes from 'prop-types';

function TeamRow({row}) {
  return <tr>
    <th>
      {row.name}
    </th>
    {row && Object.keys(row.scores).map((round, index) => {
      let points = 0;

      row.scores[round].forEach(round => {
        points += round.points;
      });
      return <td
        className={'flex justify-end'}
        key={index}>{points}</td>;
    })}
  </tr>;
}

TeamRow.propTypes = {
  name: PropTypes.string.isRequired,
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    scores: PropTypes.array
  }).isRequired
};

export default TeamRow;