import PropTypes from 'prop-types';

import {Avatar} from '../../atoms';
import ChildrenModel from '../../models/Children';
import themes from '../../theme/theme';

const ThemedAvatar = ({children, theme, ...rest}) => {
  return <Avatar
    backgroundColor={themes[theme].avatarBackgroundColor}
    color={themes[theme].textColor}
    {...rest}>{children}</Avatar>;
};

ThemedAvatar.propTypes = {
  children: ChildrenModel,
  theme: PropTypes.string
};

ThemedAvatar.defaultProps = {
  theme: 'primary'
};

export default ThemedAvatar;
