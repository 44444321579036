import {IconBuildingCommunity, IconCalendar, IconMessageDots, IconStack2, IconTag} from '@tabler/icons-react';

import constants from '../constants/constants';

export const iconProps = {
  height: 56,
  strokeWidth: 1,
  width: 56
};

const adminDashboardCounts = (questions, companies, events, tags, prebuiltRounds) => {
  const todayEvents = events ? events.filter(constants.todayFilter) : [];
  const tomorrowEvents = events ? events.filter(constants.tomorrowFilter) : [];

  const countDefaults = {
    Icon: <IconCalendar  {...iconProps}/>,
    color: 'primary',
    showSpinner: !events,
  };

  return [[{
    ...countDefaults,
    Icon: <IconBuildingCommunity {...iconProps}/>,
    count: companies?.length,
    link: '/admin/companies',
    showSpinner: !companies,
    text: 'Companies'
  }, {
    ...countDefaults,
    count: events?.length,
    link: '/admin/events',
    text: 'Total Events'
  }, {
    ...countDefaults,
    Icon: <IconTag {...iconProps}/>,
    count: tags?.length,
    link: '/admin/tags',
    showSpinner: !tags,
    text: 'Tags'
  }, {
    ...countDefaults,
    Icon: <IconMessageDots {...iconProps}/>,
    count: questions?.length,
    link: '/admin/questions',
    showSpinner: !questions,
    text: 'Questions'
  }, {
    ...countDefaults,
    Icon: <IconStack2 {...iconProps}/>,
    count: prebuiltRounds?.length,
    link: '/admin/prebuiltRounds',
    showSpinner: !prebuiltRounds,
    text: 'Prebuilt Rounds'
  }], [{
    ...countDefaults,
    color: (!todayEvents.length ? 'secondary' : 'primary'),
    count: todayEvents.length,
    text: 'Events Today'
  }, {
    ...countDefaults,
    color: (!tomorrowEvents.length ? 'secondary' : 'primary'),
    count: tomorrowEvents.length,
    text: 'Events Tomorrow'
  }]];
};

export default adminDashboardCounts;
