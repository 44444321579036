import {addDoc, collection, getFirestore, orderBy, query, where} from 'firebase/firestore';
import {useCollection} from 'react-firebase-hooks/firestore';
import {firebaseApp} from '../firebaseApp';

const usePlans = () => {
  const [plans, loading, error] = useCollection(
    query(collection(getFirestore(firebaseApp), 'plans'), where('order', '>', 0), orderBy('order')),
    {
      snapshotListenOptions: {includeMetadataChanges: true}
    }
  );

  const addPlan = async newPlan => {
    return await addDoc(collection(getFirestore(firebaseApp), 'plans'), newPlan);
  };

  return {
    addPlan,
    error,
    loading,
    plans: plans ? plans.docs : []
  };
};

export default usePlans;
