import {makeStyles} from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  feature: {
    alignItems: 'center',
    display: 'inline-flex',
    fontSize: '2rem',
    height: '4rem',
    justifyContent: 'center',
    minWidth: '4rem',
    padding: '0 8px'
  }
});

const HomeCountFeature = ({label, text, values}) => {
  const classes = useStyles();

  return <div className="col-lg-6 col-xxl-4 mb-3">
    <div className="card bg-light border-0 h-100">
      <div className="card-body text-center p-4 p-lg-3 pt-0 pt-lg-0">
        <div className={clsx(classes.feature, 'bg-secondary bg-gradient text-white rounded-3 mb-4 mt-n4')}>
          {values?.length}
        </div>
        <h2 className="fs-4 fw-bold">{label}</h2>
        <p className="mb-0">
          {text}
        </p>
      </div>
    </div>
  </div>;
};

HomeCountFeature.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
  values: PropTypes.array
};

export default HomeCountFeature;
