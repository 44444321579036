import {IconGridDots} from '@tabler/icons-react';
import PropTypes from 'prop-types';

import {ThemedTooltipIconButton} from '../../molecules';

const BoardSlide = ({buttonsToRender, text}) => {
  return <div className={'py-3 pr-3 bg-sky-400 max-h-[150px] border-1 border-sky-700 rounded mb-1'}>
    <div className={'flex text-white pl-3'}>
      <div className={'flex flex-col handle pr-2 cursor-grab items-center pt-1'}>
        <IconGridDots
          height={18}
          strokeWidth={1}
          width={18}/>
      </div>
      <div className={'flex font-light'}>
        <div className={'text-white'}>{text}</div>
      </div>
    </div>
    <div className={'flex justify-end'}>
      {buttonsToRender?.map((button, index) => <div
        key={`board-slide-button-${index}`}>
        <ThemedTooltipIconButton
          onClick={button.onClick}
          text={button.text}
        >
          {button.component}
        </ThemedTooltipIconButton>
      </div>)}
    </div>
  </div>;
};

BoardSlide.propTypes = {
  buttonsToRender: PropTypes.array,
  text: PropTypes.string
};

BoardSlide.defaultProps = {
  buttonsToRender: [],
  text: ''
};

export default BoardSlide;
