import {makeStyles} from '@mui/styles';
import {IconUserPlus} from '@tabler/icons-react';
import {useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {H2} from '../../../../atoms';

import useEvent from '../../../../hooks/useEvent';
import useEventTeams from '../../../../hooks/useEventTeams';
import {ConditionalSpinner, ThemedFAB} from '../../../../molecules';
import NewTeam from './newTeam';

const useStyles = makeStyles({
  fab: {
    alignSelf: 'flex-end'
  },
  header: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%'
  }
});

const Teams = () => {
  const {id: eventId} = useParams();
  const {eventTeams, eventTeamsLoading, addEventTeam} = useEventTeams(eventId);
  const {event} = useEvent(eventId);
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  const closeDialog = async value => {
    if (value) {
      await addEventTeam({
        event: eventId,
        name: value.name,
        scores: value.scores
      });
    }
    setDialogOpen(false);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  return (
    <>
      <div className={classes.header}>
        <ThemedFAB
          onClick={openDialog}
          theme="secondary">
          <IconUserPlus/>
        </ThemedFAB>
      </div>
      <H2>Teams:</H2>
      <ConditionalSpinner showSpinnerWhen={eventTeamsLoading}>
        <ul>
          {eventTeams?.map(eventTeam => (
            <li key={eventTeam.id}>
              <Link
                to={`/app/events/${eventId}/teams/${eventTeam.id}`}>{eventTeam.data().name}</Link>
            </li>
          ))}
        </ul>
      </ConditionalSpinner>
      <NewTeam
        onClose={closeDialog}
        open={dialogOpen}
        slideshowId={event.slideshowId}/>
    </>
  );
};

export default Teams;
