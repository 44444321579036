import PropTypes from 'prop-types';
import hardcodes from '../../../../config/hardcodes';
import useUser from '../../../../hooks/useUser';
import SlideshowControls from './slideshowControls';

const FirestoreSlideshowControls = props => {
  const {user} = useUser(hardcodes.userId);

  return <SlideshowControls
    {...props}
    user={user}
  />;
};

FirestoreSlideshowControls.propTypes = {
  currentSlide: PropTypes.shape({
    questionId: PropTypes.string
  })
};

export default FirestoreSlideshowControls;
