import {IconChevronLeft, IconMenu} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import {ThemedIconButton} from '../index';

const DrawerToggle = ({open, setOpen}) => {

  const onClick = () => {
    setOpen(!open);
  };

  return <div className={'flex items-center bg-sky-500 justify-end h-14 p-2 z-50'}>
    <ThemedIconButton
      onClick={onClick}
      shape={'square'}
      theme={'primary'}>
      {open ? <IconChevronLeft/> : <IconMenu/>}
    </ThemedIconButton>
  </div>;
};

DrawerToggle.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func
};

export default DrawerToggle;
