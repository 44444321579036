import {IconDeviceFloppy} from '@tabler/icons-react';
import {useState} from 'react';
import {H2} from '../../../../atoms';

import useQuestions from '../../../../hooks/useQuestions';
import {ThemedButton} from '../../../../molecules';

const NewBulkQuestions = () => {
  const [newQuestions, setNewQuestions] = useState('');
  const {addQuestion} = useQuestions();

  const saveQuestions = () => {
    const objectifiedQuestions = newQuestions.split('\n').map(question => {
      const questionArray = question.split('|');

      return ({
        answers: questionArray[1].split(','),
        difficulty: parseInt(questionArray[3]),
        source: questionArray[2],
        tags: questionArray[4].split(','),
        text: questionArray[0]
      });
    });

    objectifiedQuestions.forEach(question => {
      addQuestion(question);
    });

    setNewQuestions('');
  };

  const handleQuestionsChange = event => {
    setNewQuestions(event.target.value);
  };

  return <div
    className={'p-2'}
  >
    <form
      autoComplete="off"
      noValidate
    >
      <div
        className={'flex flex-column'}>
        <H2>Bulk Question Add-er</H2>
        <textarea
          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          id="outlined-multiline-static"
          onChange={handleQuestionsChange}
          placeholder={'text|questions|source|difficulty|tags'}
          rows={10}
        />
        <br/>
        <ThemedButton
          onClick={saveQuestions}
          theme={'primary'}>
          <IconDeviceFloppy strokeWidth={1}/> Save
        </ThemedButton>
      </div>
    </form>
  </div>;
};

export default NewBulkQuestions;
