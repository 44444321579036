import {Autocomplete, TextField} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';

import useCommonSettings from '../../hooks/useCommonSettings';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(3)
    },
    width: 500
  }
}));

const TagInput = ({onChange}) => {
  const classes = useStyles();
  const {setting} = useCommonSettings('tags');
  const tags = (setting?.values) ? setting.values : [];

  return (
    <div className={classes.root}>
      <Autocomplete
        getOptionLabel={(option) => option}
        id="tags-standard"
        multiple
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        options={tags}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Filter By Tag"
            placeholder="Tags"
            variant="standard"
          />
        )}
      />
    </div>
  );
};

TagInput.propTypes = {
  onChange: PropTypes.func
};

export default TagInput;
