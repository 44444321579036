import {collection, getFirestore} from 'firebase/firestore';
import {useCollection} from 'react-firebase-hooks/firestore';

import {firebaseApp} from '../firebaseApp';

const useSlideTemplates = () => {
  const [slideTemplateProps, loading, error] = useCollection(
    collection(getFirestore(firebaseApp), 'slideTemplates'),
    {
      snapshotListenOptions: {includeMetadataChanges: true}
    }
  );

  return {
    error,
    loading,
    slideTemplates: slideTemplateProps?.docs
  };
};

const useSlideTemplateProps = (slideTemplateId) => {
  const [slideTemplateProps, loading, error] = useCollection(
    collection(getFirestore(firebaseApp), `slideTemplates/${slideTemplateId}`, 'props'),
    {
      snapshotListenOptions: {includeMetadataChanges: true}
    }
  );

  return {
    slideTemplateError: error,
    slideTemplateLoading: loading,
    slideTemplateProps: slideTemplateProps?.docs
  };
};

export default useSlideTemplates;

export {useSlideTemplateProps};
