import PropTypes from 'prop-types';
import {useRanger} from 'react-ranger';

import {noop} from '../../utils';

const Slider = ({min, max, onChange, values}) => {

  const {getTrackProps, handles} = useRanger({
    max,
    min,
    onChange,
    stepSize: 1,
    values
  });

  return <div
    {...getTrackProps({
      className: 'h-[4px] bg-gray-300'
    })}>
    {handles?.map(({getHandleProps, value}, index) => (
      <div
        key={`slider-handle-${index}`}
        {...getHandleProps({
          className: 'flex justify-center items-center h-6 w-6 rounded-full bg-gray-400 cursor-grab text-white p-1 text-xs'
        })}
      >{value}</div>
    ))}
  </div>;
};

Slider.propTypes = {
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.number)
};

Slider.defaultProps = {
  onChange: noop,
  values: [0]
};

export default Slider;
