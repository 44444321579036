import {
  IconBuildingCommunity,
  IconCalendar,
  IconDashboard,
  IconDeviceGamepad,
  IconMessageDots,
  IconSlideshow
} from '@tabler/icons-react';
import {drawerMenuIconProps} from '../molecules/drawerMenuItem/drawerMenuItem';

const customerNavLinks = [{
  href: '/app/dashboard',
  icon: <IconDashboard {...drawerMenuIconProps}/>,
  text: 'Dashboard',
  type: 'link'
}, {
  type: 'divider'
}, {
  href: '/app/events',
  icon: <IconCalendar {...drawerMenuIconProps}/>,
  text: 'Events',
  type: 'link'
}, {
  href: '/app/questions',
  icon: <IconMessageDots {...drawerMenuIconProps}/>,
  text: 'Question Library',
  type: 'link'
}, {
  href: '/app/slideshows',
  icon: <IconSlideshow {...drawerMenuIconProps}/>,
  text: 'Slideshows',
  type: 'link'
}, {
  type: 'divider'
}, {
  href: '/app/venues',
  icon: <IconBuildingCommunity {...drawerMenuIconProps}/>,
  text: 'Venues',
  type: 'link'
}, {
  type: 'divider'
}, {
  href: '/tools/remote',
  icon: <IconDeviceGamepad {...drawerMenuIconProps}/>,
  text: 'Event Remote',
  type: 'link'
}];

export default customerNavLinks;
