import {IconPencil, IconRefresh, IconTrash} from '@tabler/icons-react';
import {random as _random} from 'lodash';
import PropTypes from 'prop-types';

import useQuestion from '../../hooks/useQuestion';
import RoundModel from '../../models/Round';
import BoardSlide from './boardSlide';

const FirestoreBoardSlide = ({index, deleteSlide, editSlide, questionOptions, round, saveSlide, slide}) => {
  const questionId = round?.slides[index]?.questionId;
  const {question} = questionId ? useQuestion(questionId) : {};

  const onClickDelete = () => {
    deleteSlide(index, round);
  };

  const onClickEdit = () => {
    editSlide();
  };

  const onClickRefresh = () => {
    const randomIndex = _random(0, questionOptions.length);

    slide.questionId = questionOptions[randomIndex];
    saveSlide(round, index, slide);
  };

  const deleteButton = {
    component: <IconTrash/>,
    onClick: onClickDelete,
    text: 'Delete Question'
  };

  const prebuiltRoundButtons = [{
    component: <IconRefresh/>,
    onClick: onClickRefresh,
    text: 'Randomize Question'
  }, deleteButton];

  let buttonsToRender = [{
    component: <IconPencil/>,
    onClick: onClickEdit,
    text: 'Edit Question'
  }, deleteButton];

  if (questionOptions.length) {
    buttonsToRender = prebuiltRoundButtons;
  }

  return <BoardSlide
    buttonsToRender={buttonsToRender}
    text={question?.text}/>;
};

FirestoreBoardSlide.propTypes = {
  deleteSlide: PropTypes.func,
  editSlide: PropTypes.func,
  index: PropTypes.number,
  questionOptions: PropTypes.array,
  round: RoundModel,
  saveSlide: PropTypes.func,
  slide: PropTypes.object
};

FirestoreBoardSlide.defaultProps = {
  questionOptions: []
};

export default FirestoreBoardSlide;
