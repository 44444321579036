import {useParams} from 'react-router-dom';

import usePrebuiltRound from '../../../../hooks/usePrebuiltRound';
import PrebuiltRound from './prebuiltRound';

const FirestorePrebuiltRound = () => {
  const {prebuiltRoundId} = useParams();
  const {prebuiltRound, prebuiltRoundLoading, setPrebuiltRound} = usePrebuiltRound(prebuiltRoundId);
  return <PrebuiltRound
    loading={prebuiltRoundLoading}
    prebuiltRound={prebuiltRound}
    prebuiltRoundId={prebuiltRoundId}
    setPrebuiltRound={setPrebuiltRound}/>;
};

export default FirestorePrebuiltRound;
