import PropTypes from 'prop-types';

const Address = PropTypes.shape({
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  phone: PropTypes.string,
  state: PropTypes.string,
  zipcode: PropTypes.string
});

export default Address;
