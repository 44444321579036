import PropTypes from 'prop-types';
import ChildrenModel from '../../models/Children';

const P = ({children, className}) => <p className={`text-gray-400 my-1 ${className}`} >{children}</p>;

P.propTypes = {
  children: ChildrenModel,
  className: PropTypes.string
};

export default P;
