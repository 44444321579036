import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';

import SlideWrapper from './slideWrapper';

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Title = ({title, subtitle, ...rest}) => {
  const classes = useStyles({color: 'green'});

  return (
    <SlideWrapper {...rest}>
      <div className={classes.root}>
        <h1>{title}</h1>
        <h3>{subtitle}</h3>
      </div>
    </SlideWrapper>
  );
};

Title.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default Title;
