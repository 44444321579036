import {useParams} from 'react-router-dom';
import useSlideshow from '../../hooks/useSlideshow';
import BoardSlideshow from './boardSlideshow';

const FirestoreBoardSlideshow = () => {
  const {slideshowId} = useParams();
  const {slideshow, loading, setSlideshow} = useSlideshow(slideshowId);

  return <BoardSlideshow
    loading={loading}
    setSlideshow={setSlideshow}
    slideshow={slideshow}
    slideshowId={slideshowId} />;
};

export default FirestoreBoardSlideshow;
