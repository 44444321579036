import {Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme} from '@mui/material';
import {IconX} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import TypeSenseQuestions from '../pages/app/questions/typeSenseQuestions';

const QuestionSelectionDialog = ({handleClose, multiSelect, open, rowSelected}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return <Dialog
    aria-labelledby="responsive-dialog-title"
    fullScreen={fullScreen}
    onClose={handleClose}
    open={open}
  >
    <DialogTitle id="responsive-dialog-title">
      Question Selection
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          color: (theme) => theme.palette.grey[500],
          position: 'absolute',
          right: 8,
          top: 8
        }}
      >
        <IconX/>
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <TypeSenseQuestions
        multiSelect={multiSelect}
        rowSelected={rowSelected}
      />
    </DialogContent>
  </Dialog>;
};

QuestionSelectionDialog.propTypes = {
  handleClose: PropTypes.func,
  multiSelect: PropTypes.bool,
  open: PropTypes.bool,
  rowSelected: PropTypes.func
};

QuestionSelectionDialog.defaultProps = {
  multiSelect: false
};

export default QuestionSelectionDialog;
