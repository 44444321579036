import {addDoc, collection, getFirestore, where} from 'firebase/firestore';
import hardcodes from '../config/hardcodes';
import {firebaseApp} from '../firebaseApp';
import useTriviaCollection from './useTriviaCollection';

const useEvents = (companyId = hardcodes.companyId) => {
  const [events, loading, error] = useTriviaCollection('events', [where('companyId', '==', companyId)]);

  const addEvent = async newEvent => {
    return await addDoc(collection(getFirestore(firebaseApp), 'events'), newEvent);
  };

  return {
    addEvent,
    error,
    events: events ? events.docs : [],
    loading
  };
};

export default useEvents;
