import {useState} from 'react';
import {H2, Switch} from '../../../../atoms';

import usePlans from '../../../../hooks/usePlans';
import {ConditionalSpinner} from '../../../../molecules';
import SiteFooter from '../../../siteFooter/siteFooter';
import SiteHeader from '../../../siteHeader/siteHeader';
import PricingCard from './pricingCard';

import './pricingPage.scss';

const PricingPage = () => {
  const {plans, loading: plansLoading, error} = usePlans();
  const [yearly, setYearly] = useState(false);

  const togglePayFrequency = () => {
    setYearly(!yearly);
  };

  return <div
    id="pricing-page">
    <SiteHeader/>

    <div className={'flex justify-center items-center w-full'}>
      <H2>Pricing</H2>
    </div>

    <main className="container">
      <div className={'flex flex-row justify-center mb-4'}>
        <div>Monthly</div>
        <Switch
          className={'mx-2'}
          onChange={togglePayFrequency}
          value={yearly}/>
        <div>Yearly</div>
      </div>

      <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
        {error && JSON.stringify(error)}

        <ConditionalSpinner showSpinnerWhen={plansLoading}>
          {plans?.map(plan => (
            <PricingCard
              key={plan.id}
              plan={plan.data()}
              yearly={yearly}/>
          ))}
        </ConditionalSpinner>
      </div>
    </main>

    <SiteFooter/>
  </div>;
};

export default PricingPage;
