import PropTypes from 'prop-types';
import useKeypress from 'react-use-keypress';

import useBoardSlideshow from '../../hooks/useBoardSlideshow';
import EventModel from '../../models/Event';
import {ConditionalSpinner} from '../../molecules';
import BoardSlideshowTransitionSlide from './boardSlideshowTransitionSlide';
import FirestoreBoardSlideshowAnswerSlide from './firestoreBoardSlideshowAnswerSlide';
import FirestoreBoardSlideshowQuestionSlide from './firestoreBoardSlideshowQuestionSlide';

const BoardSlideshowRenderer = ({event, setEvent, slideshowId, small}) => {
  const {loading, nextSlide, previousSlide, getCurrentSlide} = useBoardSlideshow(slideshowId, event, setEvent);

  useKeypress('ArrowLeft', () => {
    previousSlide().catch(console.error);
  });

  useKeypress('ArrowRight', async () => {
    await nextSlide();
  });

  const shouldShowQuestionSlide = () => {
    return event.activeSlide >= 0 && !event.showingAnswers;
  };

  const shouldShowAnswerSlide = () => {
    return event.activeSlide >= 0 && event.showingAnswers;
  };

  const shouldShowTransition = () => {
    return event.activeSlide < 0;
  };

  return <ConditionalSpinner showSpinnerWhen={loading}>
    { shouldShowQuestionSlide() && <FirestoreBoardSlideshowQuestionSlide
      questionId={getCurrentSlide(event)?.questionId}
      round={event.activeRound + 1}
      slide={event.activeSlide + 1}
      small={small} />
    }
    { shouldShowAnswerSlide() && <FirestoreBoardSlideshowAnswerSlide
      questionId={getCurrentSlide(event)?.questionId}
      round={event.activeRound + 1}
      slide={event.activeSlide + 1}
      small={small} />
    }
    {
      shouldShowTransition() && <BoardSlideshowTransitionSlide
        round={event.activeRound + 1}
        showingAnswers={event.showingAnswers}
        small={small} />
    }
  </ConditionalSpinner>;
};

BoardSlideshowRenderer.propTypes = {
  event: EventModel,
  setEvent: PropTypes.func,
  slideshowId: PropTypes.string,
  small: PropTypes.bool
};

export default BoardSlideshowRenderer;
