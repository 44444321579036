import PropTypes from 'prop-types';

const RoundQuestionFooter = ({question, round}) => <div className={'flex justify-between'}>
  {round && <div className={'font-light text-3xl'}>Round: {round}</div>}
  {question && <div className={'font-light text-3xl'}>Question: {question}</div>}
</div>;

RoundQuestionFooter.propTypes = {
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  round: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default RoundQuestionFooter;
