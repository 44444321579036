import {doc, getFirestore, setDoc} from 'firebase/firestore';
import {forEachRight as _forEachRight} from 'lodash';

import {firebaseApp} from '../firebaseApp';
import getCurrentSlideFromSlideshow from '../utils/getCurrentSlideFromSlideshow';
import useTriviaDocument from './useTriviaDocument';

const useSlideshow = slideshowId => {
  const [document, loading, error] = useTriviaDocument('slideshows', slideshowId);

  const currentSlideshow = document?.data();

  const previousSlide = async () => {
    if (!currentSlideshow) {
      return;
    }

    if (currentSlideshow.activeSlide === 0) {
      return;
    }

    currentSlideshow.activeSlide--;

    return setSlideshow(currentSlideshow, slideshowId);
  };

  const nextSlide = async () => {
    if (!currentSlideshow) {
      return;
    }

    const slideLength = Object.keys(currentSlideshow.slides).length;

    if (currentSlideshow.activeSlide >= slideLength - 1) {
      return;
    }

    currentSlideshow.activeSlide++;

    return setSlideshow(currentSlideshow, slideshowId);
  };

  const setSlideshow = async (slideshow, slideshowId = slideshowId) => {
    let rounds = 0;

    _forEachRight(slideshow.slides, slide => {
      if (slide.template === 'question') {
        rounds = slide.props.round;
        return false;
      }
    });

    slideshow.rounds = rounds;

    return await setDoc(doc(getFirestore(firebaseApp), 'slideshows', slideshowId), slideshow);
  };

  const getCurrentSlide = () => {
    return getCurrentSlideFromSlideshow(currentSlideshow);
  };

  return {
    error,
    getCurrentSlide,
    loading,
    nextSlide,
    previousSlide,
    setSlideshow,
    slideshow: currentSlideshow
  };
};

export default useSlideshow;
