import PropTypes from 'prop-types';
import {useState} from 'react';

import ChildrenModel from '../../models/Children';
import DrawerToggle from './drawerToggle';

const Drawer = ({children, open}) => {
  const [drawerOpen, setDrawerOpen] = useState(open);

  return <div className={'fixed left-0 top-0 bottom-0 z-50'}>
    <div className={`flex flex-col ${drawerOpen ? 'w-[240px]' : 'w-[60px]'} h-full bg-white absolute left-0 top-0 bottom-0 ease-in-out duration-300`}>
      <DrawerToggle
        open={drawerOpen}
        setOpen={setDrawerOpen}/>
      <div className={'h-full border-r border-r-gray-300'}>
        {children}
      </div>
    </div>
  </div>;
};

Drawer.propTypes = {
  children: ChildrenModel,
  open: PropTypes.bool
};

export default Drawer;
