import PropTypes from 'prop-types';

import ChildrenModel from '../../models/Children';

const Paper = ({className, children}) => <div className={`shadow box-shadow box-shadow-md rounded-sm w-fit ${className}`}>{children}</div>;

Paper.propTypes = {
  children: ChildrenModel,
  className: PropTypes.string
};

export default Paper;
