import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

const SiteNavLink = ({linkClasses, link}) => {
  return <li className="nav-item">
    <Link
      className={`nav-link ${linkClasses}`}
      to={{
        hash: link.hash,
        pathname: link.url
      }}>{link.text}</Link>
  </li>;
};

SiteNavLink.propTypes = {
  link: PropTypes.shape({
    hash: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string
  }),
  linkClasses: PropTypes.string
};

export default SiteNavLink;
