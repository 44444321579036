import {doc, getFirestore, setDoc} from 'firebase/firestore';

import {firebaseApp} from '../firebaseApp';
import useTriviaDocument from './useTriviaDocument';

export const LAST_SLIDE_OF_SLIDESHOW = 999;
export const LAST_SLIDE_OF_ROUND = 888;

const useBoardSlideshow = (slideshowId, event, setEvent) => {
  const [document, loading, error] = useTriviaDocument('slideshows', slideshowId);

  const currentSlideshow = document?.data();

  const previousSlide = async () => {
    if (!currentSlideshow) {
      return;
    }

    if (event.activeSlide === 0) {
      if (!event.showingAnswers) {
        if (event.activeRound === 0) {
          return;
        }

        event.activeRound--;
        event.activeSlide = currentSlideshow.round[event.activeRound].slides.length - 1;
        event.showingAnswers = true;
      } else {
        event.activeSlide = currentSlideshow.round[event.activeRound].slides.length - 1;
        event.showingAnswers = false;
      }
    } else {
      event.activeSlide--;
    }

    await setEvent(event, event.id);
    return setSlideshow(currentSlideshow, slideshowId);
  };

  const nextSlide = async () => {
    if (!currentSlideshow) {
      return;
    }

    if (event.activeSlide >= currentSlideshow.round[event.activeRound].slides.length - 1) {
      if (event.activeRound === currentSlideshow.round.length -1 && event.showingAnswers) {
        return;
      }

      if (event.showingAnswers) {
        event.activeSlide = 0;
        event.activeRound++;
        event.showingAnswers = false;
      } else {
        event.activeSlide = 0;
        event.showingAnswers = true;
      }
    } else {
      event.activeSlide++;
    }

    await setEvent(event, event.id);
    await setSlideshow(currentSlideshow, slideshowId);
  };

  const setSlideshow = async (slideshow, slideshowId = slideshowId) => {
    slideshow.rounds = slideshow.round.length;

    return await setDoc(doc(getFirestore(firebaseApp), 'slideshows', slideshowId), slideshow);
  };

  const getCurrentSlide = event => {
    if (currentSlideshow && event) {
      return currentSlideshow?.round[event.activeRound]?.slides[event.activeSlide];
    }
  };

  const toggleShowingAnswers = () => {
    event.showingAnswers = !event.showingAnswers;
    setEvent(event);
  };

  const goToFirstSlideOfRound = () => {
    event.activeSlide = 0;
    setEvent(event);
  };

  return {
    error,
    getCurrentSlide,
    goToFirstSlideOfRound,
    loading,
    nextSlide,
    previousSlide,
    setSlideshow,
    slideshow: currentSlideshow,
    toggleShowingAnswers
  };
};

export default useBoardSlideshow;
