import dayjs from 'dayjs';
import {where} from 'firebase/firestore';

import hardcodes from '../config/hardcodes';
import useTriviaCollection from './useTriviaCollection';

const useEventsByDate = (date = dayjs(), companyId = hardcodes.companyId) => {
  const [events, loading, error] = useTriviaCollection('events', [
    where('companyId', '==', companyId),
    where('startDtm', '>=', date.toDate()),
    where('startDtm', '<', date.add(1, 'day').toDate())
  ]);

  return {
    error,
    events: events ? events.docs : [],
    loading
  };
};

export default useEventsByDate;