import PropTypes from 'prop-types';
import {IconButton} from '../../atoms';
import ChildrenModel from '../../models/Children';
import ThemeModel from '../../models/Theme';
import getButtonClasses from '../../theme/getButtonClasses';
import {noop} from '../../utils';

const ThemedIconButton = ({children, className, onClick, theme, ...rest}) => <IconButton
  className={`${getButtonClasses(theme)} ${className}`}
  onClick={onClick}
  {...rest}>{children}</IconButton>;

ThemedIconButton.propTypes = {
  children: ChildrenModel,
  className: PropTypes.string,
  onClick: PropTypes.func,
  theme: ThemeModel
};

ThemedIconButton.defaultProps = {
  className: '',
  onClick: noop,
  theme: 'default'
};

export default ThemedIconButton;
