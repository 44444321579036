import useContactRequests from '../../../../hooks/useContactRequests';
import {ConditionalTable} from '../../../../molecules';

const contactRequestColumns = [{
  Header: 'Name',
  accessor: 'name'
}, {
  Header: 'E-mail',
  accessor: 'email'
}, {
  Header: 'Actions',
  accessor: 'actions'
}];

const ContactRequests = () => {
  const {contactRequests, loading} = useContactRequests();

  const getMappedContactRequests = () => contactRequests?.map(request => ({
    actions: null,
    email: request.data().email,
    name: request.data().name
  }));

  return <ConditionalTable
    columns={contactRequestColumns}
    data={getMappedContactRequests()}
    loading={loading}
  />;
};

export default ContactRequests;
