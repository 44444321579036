import {addDoc, collection, getFirestore} from 'firebase/firestore';
import {useCollection} from 'react-firebase-hooks/firestore';

import {firebaseApp} from '../firebaseApp';

const useQuestions = () => {
  const [questions, loading, error] = useCollection(
    collection(getFirestore(firebaseApp), 'questions'),
    {
      snapshotListenOptions: {includeMetadataChanges: true}
    }
  );

  const addQuestion = async newValue => {
    return await addDoc(collection(getFirestore(firebaseApp), 'questions'), {...newValue, difficulty: parseInt(newValue.difficulty)});
  };

  return {
    addQuestion,
    error,
    loading,
    questions: questions?.docs
  };
};

export default useQuestions;
