import {IconPencil, IconPlus} from '@tabler/icons-react';
import {useHistory} from 'react-router-dom';

import usePrebuiltRounds from '../../../../hooks/usePrebuiltRounds';
import {ConditionalTable, ThemedFAB, ThemedTooltipIconButton} from '../../../../molecules';

const prebuiltRoundColumns = [{
  Header: 'Title',
  accessor: 'title'
}, {
  Header: 'Questions',
  accessor: 'questionCount'
}, {
  Header: 'Actions',
  accessor: 'actions'
}];

const PrebuiltRounds = () => {
  const history = useHistory();
  const {loading, prebuiltRounds} = usePrebuiltRounds();

  const viewPrebuiltRound = prebuiltRoundId => () => history.push(`/admin/prebuiltRounds/${prebuiltRoundId}`);

  const getMappedRounds = () => prebuiltRounds?.map(round => ({
    actions: <ThemedTooltipIconButton
      onClick={viewPrebuiltRound(round?.id)}
      text={'Edit Prebuilt Round'}>
      <IconPencil strokeWidth={1}/>
    </ThemedTooltipIconButton>,
    questionCount: round?.data().questions.length,
    title: round?.data()?.title
  }));

  return <>
    <ConditionalTable
      columns={prebuiltRoundColumns}
      data={getMappedRounds()}
      loading={loading}
    />
    <ThemedFAB
      onClick={() => {}}
      style={{
        position: 'absolute',
        right: '40px',
        top: '80px'
      }}
      theme="primary">
      <IconPlus/>
    </ThemedFAB>
  </>;
};

export default PrebuiltRounds;
