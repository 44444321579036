import {IconPlus} from '@tabler/icons-react';
import PropTypes from 'prop-types';

const TeamMemberCard = ({teamMember}) => {
  return (
    <div className="col-xs-12 col-sm-6 col-md-4">
      <div className="image-flip">
        <div className="mainflip flip-0">
          <div className="frontside">
            <div className="card">
              <div className="card-body text-center">
                <p><img
                  alt="card image"
                  className=" img-fluid"
                  src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_01.png"/></p>
                <h4 className="card-title">{teamMember.name}</h4>
                <p className="card-text">{teamMember.shortBiography}</p>
                <a
                  className="btn btn-primary btn-sm"
                  href="#">
                  <IconPlus strokeWidth={1}/>
                </a>
              </div>
            </div>
          </div>
          <div className="backside">
            <div className="card">
              <div className="card-body text-center mt-4">
                <h4 className="card-title">{teamMember.name}</h4>
                <p className="card-text">{teamMember.biography}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TeamMemberCard.propTypes = {
  teamMember: PropTypes.shape({
    biography: PropTypes.string,
    name: PropTypes.string,
    shortBiography: PropTypes.string
  })
};

export default TeamMemberCard;
