const getInitials = name => {
  let initials = '';
  if (typeof name === 'string' && name.length) {
    initials += name[0].toUpperCase();

    const splitName = name.split(' ');

    if (splitName[1]) {
      initials += splitName[1][0].toUpperCase();
    }
  }

  return initials;
};

export default getInitials;