import siteFeatures from '../../../../config/siteFeatures';
import HomeFeature from './homeFeature';

const HomeFeatureList = () => {
  return <div className="container px-4 py-5">
    <h2
      className="pb-2 border-bottom"
      id="features">Features</h2>
    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
      {siteFeatures?.map(feature => (
        <HomeFeature
          key={feature.text}
          text={feature.text}
          title={feature.title}/>
      ))}
    </div>
  </div>;
};

export default HomeFeatureList;
