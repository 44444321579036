import PropTypes from 'prop-types';

import ChildrenModel from '../../models/Children';
import getButtonClasses from '../../theme/getButtonClasses';

const Button = ({children, className, ...rest}) => <div
  className={`flex justify-center cursor-pointer rounded  px-4 py-2 ${className}`}
  {...rest}>{children}</div>;

Button.propTypes = {
  children: ChildrenModel,
  className: PropTypes.string
};

Button.defaultProps = {
  className: getButtonClasses('default')
};

export default Button;
