import TeamModel from '../../models/Team';
import {getTeamScores, getTeamTotalScore} from '../../utils/teams';

const ScoreboardRow = ({team}) => {
  return <div className={'flex flex-row text-2xl text-uppercase'}>{team.name}
    <div
      className={'text-2xl pl-4 flex'}
      id="score-sd">{team && getTeamScores(team).map((score, index) => <div
        className={'pr-4 w-[3rem] text-right'}
        key={`round-${index}`}>{score}</div>)}
      <div
        className={'pr-4 w-[3rem] text-right'}>{getTeamTotalScore(team)}</div>
    </div>
  </div>;
};

ScoreboardRow.propTypes = {
  team: TeamModel
};

ScoreboardRow.defaultProps = {
  team: {}
};

export default ScoreboardRow;
