import {addDoc, collection, getFirestore, where} from 'firebase/firestore';

import {firebaseApp} from '../firebaseApp';
import useTriviaCollection from './useTriviaCollection';

const useEventTeams = eventId => {
  const [eventTeams, loading, error] = useTriviaCollection('teams', [where('event', '==', eventId)]);

  const addEventTeam = async newTeam => {
    return await addDoc(collection(getFirestore(firebaseApp), 'teams'), newTeam);
  };

  return {
    addEventTeam,
    eventTeams: eventTeams ? eventTeams.docs : [],
    eventTeamsError: error,
    eventTeamsLoading: loading
  };
};

export default useEventTeams;
