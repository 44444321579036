import {IconCircleCheck, IconHeart, IconPencil} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import hardcodes from '../../../../config/hardcodes';
import useTypeSenseQuestions from '../../../../hooks/useTypeSenseQuestions';
import useUser from '../../../../hooks/useUser';
import {ThemedTooltipIconButton} from '../../../../molecules';
import TextShortener from '../../../textShortener/textShortener';
import Questions from './questions';

const QUESTION_TEXT_LENGTH = 50;

const goToNewQuestion = history => () => {
  return history.push('/admin/questions/new');
};

const questionColumns = [{
  Header: 'Question',
  accessor: 'question'
}, {
  Header: 'Actions',
  accessor: 'actions'
}];

const TypeSenseQuestions = ({multiSelect, rowSelected, selectedQuestions}) => {
  const [, setTagFilters] = useState('');
  const [filterText, setFilterText] = useState('');
  const [buttonFilters, setButtonFilters] = useState(() => []);
  const [, setFavoritesOnly] = useState(false);
  const [difficultyRange, setDifficultyRange] = useState([1, 5]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(100);
  const [pageCount, setPageCount] = useState(0);
  const {questions, searchResults, loading} = useTypeSenseQuestions(filterText, difficultyRange, page, pageSize);
  const {isSuperAdmin} = useUser(hardcodes.userId);
  const {user, setUser} = useUser(hardcodes.userId);

  const history = useHistory();

  useEffect(() => {
    setPageCount(Math.ceil(searchResults.found/pageSize));
  }, [searchResults]);

  const changeHandler = value => {
    setTagFilters(value);
  };

  const onChangeFilterText = e => {
    setFilterText(e.target.value);
  };

  const getIndexOfQuestionInFavorites = questionId => {
    return user?.favoriteQuestions?.indexOf(questionId);
  };

  const isFavorite = questionId => {
    return getIndexOfQuestionInFavorites(questionId) > -1;
  };

  const favoriteClicked = questionId => () => {
    if (isFavorite(questionId)) {
      user.favoriteQuestions.splice(getIndexOfQuestionInFavorites(), 1);
    } else {
      user.favoriteQuestions.push(questionId);
    }

    setUser(user, hardcodes.userId);
  };

  const buttonFiltersChanged = (event, newButtonFilters) => {
    setButtonFilters(newButtonFilters);
    setFavoritesOnly(newButtonFilters.indexOf('favorites') > -1);
  };

  const viewQuestion = questionId => () => history.push(`/app/questions/${questionId}`);

  const handleDifficultyChange = newValue => {
    setDifficultyRange(newValue);
  };

  const getFavoriteChipColor = questionId => {
    return isFavorite(questionId) ? 'secondary' : 'default';
  };

  function isQuestionSelected(question) {
    return selectedQuestions?.indexOf(question.document.id) > -1;
  }

  const getMappedQuestions = () => questions?.map(question => ({
    actions: <div className={'flex'}>
      <ThemedTooltipIconButton
        onClick={favoriteClicked}
        text={'Favorite'}
        theme={getFavoriteChipColor(question.document.id)}>
        <IconHeart
          fill={isFavorite(question.document.id) ? 'text-red-600' : 'none'}
          strokeWidth={1}/>
      </ThemedTooltipIconButton>
      <ThemedTooltipIconButton
        onClick={viewQuestion(question.document.id)}
        text={'Edit Question'}>
        <IconPencil strokeWidth={1}/>
      </ThemedTooltipIconButton>
      {rowSelected && <ThemedTooltipIconButton
        onClick={() => {
          rowSelected({
            questionId: question.document.id,
            selected: !isQuestionSelected(question)
          });
        }}
        text={'Select Question'}
        theme={isQuestionSelected(question) ? 'primary' : 'default'}
      >
        <IconCircleCheck strokeWidth={1}/>
      </ThemedTooltipIconButton>}
    </div>,
    multiSelect,
    question: <TextShortener
      length={QUESTION_TEXT_LENGTH}
      onClickMore={viewQuestion}
      text={question?.document.text}/>,
    selected: isQuestionSelected(question)
  }));

  const pageChangeHandler = pageIndex => {
    setPage(pageIndex);
  };

  return <Questions
    buttonFilters={buttonFilters}
    buttonFiltersChanged={buttonFiltersChanged}
    changeHandler={changeHandler}
    difficultyRange={difficultyRange}
    filterText={filterText}
    goToNewQuestion={goToNewQuestion}
    handleDifficultyChange={handleDifficultyChange}
    isSuperAdmin={isSuperAdmin()}
    loading={loading}
    onChangeFilterText={onChangeFilterText}
    pageChanged={pageChangeHandler}
    pageCount={pageCount}
    pageSize={pageSize}
    questionColumns={questionColumns}
    questions={getMappedQuestions()}
  />;
};

TypeSenseQuestions.propTypes = {
  multiSelect: PropTypes.bool,
  rowSelected: PropTypes.func,
  selectedQuestions: PropTypes.arrayOf(PropTypes.string)
};

TypeSenseQuestions.defaultProps = {
  multiSelect: false,
  selectedQuestions: []
};

export default TypeSenseQuestions;
