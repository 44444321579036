import triviaMCTeam from '../../../../config/triviaMCTeam';
import SiteFooter from '../../../siteFooter/siteFooter';
import SiteHeader from '../../../siteHeader/siteHeader';
import './team.scss';
import TeamMemberCard from './teamMemberCard';

const Team = () => {

  return (
    <>
      <SiteHeader/>

      <section
        className="pb-5"
        id="team">
        <div className="container">
          <h5 className="section-title h1">OUR TEAM</h5>
          <div className="row">
            {triviaMCTeam?.map((teamMember, index) => (
              <TeamMemberCard
                key={`team-member-${index}`}
                teamMember={teamMember}/>
            ))}
          </div>
        </div>
      </section>

      <SiteFooter/>
    </>
  );
};

export default Team;