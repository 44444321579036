import PropTypes from 'prop-types';

import ThemeModel from '../../models/Theme';
import themes from '../../theme/theme';
import {ConditionalSpinner} from '../index';

const DashboardCard = ({Icon, className, count, link, loading, text, theme, ...rest}) => {

  const onClick = () => {
    if (link) {
      window.location = link;
    }
  };

  return <div
    className={`flex flex-row shadow shadow-md ${link && 'cursor-pointer'} ${className}`}
    onClick={onClick}
    {...rest}>
    <div className={`${themes[theme].color} text-white p-4`}>
      {Icon}
    </div>
    <div className={`flex flex-col justify-center p-4 w-full ${link && 'hover:bg-gray-100'}`}>
      <ConditionalSpinner
        showSpinnerWhen={loading}
        theme={theme}>
        <div className={`text-lg ${themes[theme].headingColor}`}>{text}</div>
        <div className={'text-lg text-black'}>{count}</div>
      </ConditionalSpinner>
    </div>
  </div>;
};

DashboardCard.propTypes = {
  Icon: PropTypes.object,
  className: PropTypes.string,
  count: PropTypes.number,
  link: PropTypes.string,
  loading: PropTypes.bool,
  text: PropTypes.string,
  theme: ThemeModel
};

DashboardCard.defaultProps = {
  theme: 'default'
};

export default DashboardCard;
