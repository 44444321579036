import dayjs from 'dayjs';
import {useParams} from 'react-router-dom';

import hardcodes from '../../../../config/hardcodes';
import useEventsByDate from '../../../../hooks/useEventsByDate';
import useUser from '../../../../hooks/useUser';
import Events from './events';

export default function FirestoreEvents() {
  const {date} = useParams();

  const startOfDay = dayjs(date).startOf('day');
  const {user, setUser} = useUser(hardcodes.userId);
  const {events, loading} = useEventsByDate(startOfDay);

  return <Events
    events={events}
    loading={loading}
    setUser={setUser}
    user={user}/>;
}
