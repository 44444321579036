import {IconCalendar} from '@tabler/icons-react';
import {Link} from 'react-router-dom';
import {H3} from '../../../../atoms';
import {iconProps} from '../../../../config/adminDashboardCounts';

import constants from '../../../../constants/constants';
import useEvents from '../../../../hooks/useEvents';
import {ConditionalSpinner, DashboardCard} from '../../../../molecules';

const Dashboard = () => {
  const {events, loading} = useEvents();

  const todayEvents = events ? events.filter(constants.todayFilter) : [];
  const tomorrowEvents = events ? events.filter(constants.tomorrowFilter) : [];

  const dashboardCounts = [{
    count: todayEvents.length,
    icon: <IconCalendar  {...iconProps}/>,
    loading,
    text: 'Events Today'
  }, {
    count: tomorrowEvents.length,
    icon: <IconCalendar  {...iconProps}/>,
    loading,
    text: 'Events Tomorrow'
  }, {
    count: events.length,
    icon: <IconCalendar  {...iconProps}/>,
    loading,
    text: 'Total Events'
  }];

  const eventToLink = event => (
    <div key={event.id}>
      <Link to={`events/${event.id}`}>{event.data().name}</Link>
    </div>
  );

  return <ConditionalSpinner showSpinnerWhen={loading}>
    <div className={'flex flex-wrap'}>
      {dashboardCounts.map((count, index) => <DashboardCard
        Icon={count.icon}
        className={'mb-4 mr-4'}
        count={count.count}
        key={`dashboard-count-${index}`}
        loading={loading}
        text={count.text}
        theme={'primary'}
      />)}
    </div>
    <br/>
    <H3>Today&lsquo;s Events</H3>
    <br/>
    {todayEvents?.map(eventToLink)}
    <br/>
    <H3>Tomorrow&lsquo;s Events</H3>
    <br/>
    {tomorrowEvents?.map(eventToLink)}
  </ConditionalSpinner>;
};

export default Dashboard;
