import {IconTools} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import {H4} from '../../../../atoms';

const HomeFeature = ({text, title}) => {
  return (
    <div className="flex items-start">
      <div className={'flex-grow px-4'}>
        <IconTools
          height={32}
          strokeWidth={1}
          width={32}/>
      </div>
      <div>
        <H4>{title}</H4>
        <div>{text}</div>
      </div>
    </div>
  );
};

HomeFeature.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string
};

export default HomeFeature;
