import {useParams} from 'react-router-dom';

import useUser from '../../../../hooks/useUser';
import Host from './host';

const FirestoreHost = () => {
  const {hostId} = useParams();
  const {user, userLoading} = useUser(hostId);

  return <Host
    host={user}
    hostLoading={userLoading} />;
};

export default FirestoreHost;
