import PropTypes from 'prop-types';

const Question = PropTypes.shape({
  answers: PropTypes.arrayOf(PropTypes.string),
  difficulty: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string
});

export default Question;
