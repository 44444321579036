// TODO: Figure out if this file is necessary
const themeOptions = {
  palette: {
    error: {
      main: '#D81E5B',
    },
    info: {
      main: '#3772FF',
    },
    primary: {
      main: '#F0544F',
    },
    secondary: {
      main: '#3A6EA5',
    },
    success: {
      main: '#5C8001',
    },
    type: 'dark',
    warning: {
      main: '#F7F052',
    }
  },
};

export default themeOptions;
