import PropTypes from 'prop-types';

import {noop} from '../../utils';


export const getContainerStyles = value => {
  return value ? 'justify-end' : '';
};

export const getHandleStyles = value => {
  return value ? 'bg-sky-500 border-1 border-sky-600' : 'bg-gray-200 border-gray-500';
};

// eslint-disable-next-line no-unused-vars
const Switch = ({className, onChange, value, ...rest}) => {

  return <div
    className={`flex items-center h-8 w-[60px] p-1 border-1 border-gray-500 rounded-full cursor-pointer ${getContainerStyles(value)} ease-in duration-150 ${className}`}
    onClick={onChange}
    {...rest}>
    <div className={`rounded-full h-6 w-6  ${getHandleStyles(value)} ease-in duration-50`} />
  </div>;
};

Switch.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool
};

Switch.defaultProps = {
  className: '',
  onChange: noop,
  value: false
};

export default Switch;
