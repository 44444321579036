import {useRouteMatch} from 'react-router-dom';
import FirestoreBoardSlideshow from '../../boardSlideshow/firestoreBoardSlideshow';
import RouteRenderer from '../../routeRenderer/routeRenderer';
import Dashboard from './dashboard/dashboard';
import EditEvent from './editEvent/editEvent';
import Event from './event/event';
import FirestoreEvents from './events/firestoreEvents';
import FirestoreHost from './host/firestoreHost';
import Hosts from './hosts/hosts';
import FirestoreQuestion from './question/firestoreQuestion';
import TypeSenseQuestions from './questions/typeSenseQuestions';
import Scoreboard from './scoreboard/scoreboard';
import SimpleDesigner from './simpleDesigner/simpleDesigner';
import SlideshowDesigner from './slideshowDesigner/slideshowDesigner';
import Slideshows from './slideshows/slideshows';
import Team from './team/team';
import Teams from './teams/teams';
import FirestoreVenue from './venue/firestoreVenue';
import Venues from './venues/venues';

const routes = path => [{
  component: <Dashboard/>,
  heading: 'Dashboard',
  path: `${path}/`
}, {
  component: <Dashboard/>,
  heading: 'Dashboard',
  path: `${path}/dashboard`
}, {
  component: <Slideshows/>,
  heading: 'Slideshows',
  path: `${path}/slideshows`
}, {
  component: <SlideshowDesigner/>,
  heading: 'Slideshow Designer',
  path: `${path}/slideshows/:slideshowId/designer`
}, {
  component: <FirestoreBoardSlideshow/>,
  path: `${path}/slideshows/:slideshowId/board`
}, {
  component: <SimpleDesigner/>,
  heading: 'Simple Designer',
  path: `${path}/slideshows/:slideshowId/simple`
}, {
  component: <SimpleDesigner/>,
  heading: 'Simple Designer',
  path: `${path}/slideshows/:slideshowId/simple/:round`
}, {
  component: <FirestoreEvents/>,
  heading: 'Events',
  path: `${path}/events`
}, {
  component: <FirestoreEvents/>,
  heading: 'Events',
  path: `${path}/events/date/:date`
}, {
  component: <EditEvent/>,
  path: `${path}/events/new`
}, {
  component: <Team/>,
  path: `${path}/events/:id/teams/:teamId`
}, {
  component: <Teams/>,
  path: `${path}/events/:id/teams`
}, {
  component: <Scoreboard/>,
  path: `${path}/events/:id/scoreboard`
}, {
  component: <Event/>,
  path: `${path}/events/:id`
}, {
  component: <EditEvent/>,
  path: `${path}/events/:id/edit`
}, {
  component: <FirestoreQuestion/>,
  path: `${path}/questions/:questionId`
}, {
  component: <TypeSenseQuestions/>,
  path: `${path}/questions`
}, {
  component: <Venues/>,
  heading: 'Venues',
  path: `${path}/venues`
}, {
  component: <FirestoreVenue/>,
  path: `${path}/venues/:venueId`
}, {
  component: <Hosts/>,
  heading: 'Hosts',
  path: `${path}/hosts`
}, {
  component: <FirestoreHost/>,
  path: `${path}/hosts/:hostId`
}];

const Index = () => {
  const {path} = useRouteMatch();

  return <RouteRenderer routes={routes(path)}/>;
};

export default Index;
