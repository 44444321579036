import PropTypes from 'prop-types';

const LoadingSpinner = ({className, ...rest}) => {
  return <div
    className={`flex justify-center items-center ${className}`}
    {...rest}>
    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>;
};

LoadingSpinner.propTypes = {
  className: PropTypes.string
};

export default LoadingSpinner;
