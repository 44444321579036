import {addDoc, collection, getFirestore} from 'firebase/firestore';

import {firebaseApp} from '../firebaseApp';
import useTriviaCollection from './useTriviaCollection';

const useContactRequests = () => {
  const [contactRequests, loading, error] = useTriviaCollection('contactRequests');

  const addContactRequest = async newSuggestedQuestion => {
    return await addDoc(collection(getFirestore(firebaseApp), 'contactRequests'), newSuggestedQuestion);
  };

  return {
    addContactRequest,
    contactRequests: contactRequests ? contactRequests.docs : [],
    error,
    loading
  };
};

export default useContactRequests;
