import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import RoundQuestionFooter from './roundQuestionFooter';

import SlideWrapper from './slideWrapper';

const useStyles = makeStyles(() => ({
  answer: {
    alignSelf: 'center'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between'
  }
}));

const Answer = ({text, round, question, answer, ...rest}) => {
  const classes = useStyles();

  return (
    <SlideWrapper {...rest}>
      <div className={classes.root}>
        <h1>{text}</h1>
        <h1 className={classes.answer}>{answer}</h1>
        <RoundQuestionFooter
          question={question}
          round={round}/>
      </div>
    </SlideWrapper>
  );
};

Answer.propTypes = {
  answer: PropTypes.string.isRequired,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  round: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string.isRequired
};

export default Answer;
