import PropTypes from 'prop-types';
import ChildrenModel from '../../../../models/Children';

const SlideshowControlButton = ({children, onClick}) => {
  return <div
    className={'border-1 border-sky-700 text-sky-700 hover:bg-sky-500 rounded p-4 ease-in duration-150 cursor-pointer'}
    onClick={onClick}
  >
    {children}
  </div>;
};

SlideshowControlButton.propTypes = {
  children: ChildrenModel,
  onClick: PropTypes.func
};

export default SlideshowControlButton;
