import {IconTrash} from '@tabler/icons-react';

import useSuggestedQuestions from '../../../../hooks/useSuggestedQuestions';
import {ConditionalTable, ThemedTooltipIconButton} from '../../../../molecules';

const suggestedQuestionColumns = [{
  Header: 'Question',
  accessor: 'question'
}, {
  Header: 'Answer',
  accessor: 'answer'
}, {
  Header: 'Source',
  accessor: 'source'
}, {
  Header: 'Difficulty',
  accessor: 'difficulty'
}, {
  Header: 'Actions',
  accessor: 'actions'
}];

const SuggestedQuestions = () => {
  const {suggestedQuestions, loading} = useSuggestedQuestions();

  const getMappedQuestions = () => suggestedQuestions?.map(question => ({
    actions: <ThemedTooltipIconButton
      text={'Delete Suggestion'}
      theme={'default'}>
      <IconTrash/>
    </ThemedTooltipIconButton>,
    answer: question.data().answer,
    difficulty: question.data().difficulty,
    question: question.data().question,
    source: question.data().source
  }));

  return <ConditionalTable
    columns={suggestedQuestionColumns}
    data={getMappedQuestions()}
    loading={loading}
  />;
};

export default SuggestedQuestions;
