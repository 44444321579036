import {useParams} from 'react-router-dom';
import useEventTeams from '../../../../hooks/useEventTeams';
import {ConditionalSpinner} from '../../../../molecules';
import TeamRow from './teamRow';


export default function ScoreTable() {
  const {id: eventId} = useParams();
  // const {event} = useEvent(eventId);
  const {eventTeams, eventTeamsLoading} = useEventTeams(eventId);

  return <ConditionalSpinner showSpinnerWhen={eventTeamsLoading}>
    <div>
      <table>
        <thead>
          <tr>
            <th>Team</th>
            <th align="right">Round 1</th>
            <th align="right">2</th>
            <th align="right">3</th>
            <th align="right">4</th>
            <th align="right">5</th>
          </tr>
        </thead>
        <tbody>
          {eventTeams && Object.keys(eventTeams).map((key) => (
            <TeamRow
              key={key}
              name={key}
              row={eventTeams[key].data()}/>
          ))}
        </tbody>
      </table>
    </div>
  </ConditionalSpinner>;
}
