import {useParams} from 'react-router-dom';
import useEvent from '../../../../hooks/useEvent';
import {ConditionalSpinner} from '../../../../molecules';
import BoardSlideshowRenderer from '../../../boardSlideshowRenderer/boardSlideshowRenderer';

const LiveEvent = () => {
  const {eventId} = useParams();
  const {event, eventLoading, setEvent} = useEvent(eventId);

  return <ConditionalSpinner showSpinnerWhen={eventLoading}>
    <div className={'flex flex-col h-full w-full'}>
      <BoardSlideshowRenderer
        event={event}
        setEvent={setEvent}
        slideshowId={event?.slideshowId} />
    </div>
  </ConditionalSpinner>;
};

export default LiveEvent;
