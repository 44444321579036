import {doc, getFirestore, setDoc} from 'firebase/firestore';

import {firebaseApp} from '../firebaseApp';
import useTriviaDocument from './useTriviaDocument';

// TODO: Move this to another file; src/constants/permissions.js
const superAdmin = 'SUPER_ADMIN';

const useUser = userId => {
  const [document, loading, error] = useTriviaDocument('users', userId);

  const user = document?.data();

  const isSuperAdmin = () => {
    return user?.permissions?.indexOf(superAdmin) > -1;
  };

  const setUser = async (user, userId = userId) => {
    return await setDoc(doc(getFirestore(firebaseApp), 'users', userId), user);
  };

  return {
    isSuperAdmin,
    setUser,
    user,
    userError: error,
    userLoading: loading
  };
};

export default useUser;
