import {join as _join} from 'lodash';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';

import RoundQuestionFooter from '../slideTemplates/roundQuestionFooter';
import SlideWrapper from '../slideTemplates/slideWrapper';

const BoardSlideshowAnswerSlide = ({answers, question, round, small, text, source, ...rest}) => {
  const showSource = () => {
    return !small && source?.length;
  };

  return <SlideWrapper {...rest}>
    <div className={`flex flex-col justify-between h-full w-full ${!small && 'p-4 pt-[200]'} bg-blue-300`}>
      <div className={`${small ? 'text-2xl' : 'text-6xl'}`}>{text}</div>
      <div className={`${small ? 'text-2xl' : 'text-6xl'} text-center`}>{_join(answers, ', ')}</div>

      {showSource() && <div className={'flex justify-center w-full'}><QRCode
        size={100}
        value={source} /></div>}

      {!small && <RoundQuestionFooter
        question={question}
        round={round}/>}
    </div>
  </SlideWrapper>;
};

BoardSlideshowAnswerSlide.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.string),
  question: PropTypes.number,
  round: PropTypes.number,
  small: PropTypes.bool,
  source: PropTypes.string,
  text: PropTypes.string
};

export default BoardSlideshowAnswerSlide;
