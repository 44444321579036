import {IconDeviceFloppy} from '@tabler/icons-react';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {H2} from '../../../../atoms';
import useQuestions from '../../../../hooks/useQuestions';
import {Rating, TextField, ThemedButton} from '../../../../molecules';
import TagInput from '../../../tagInput/tagInput';

const NewQuestion = () => {
  const [question, setQuestion] = useState({});
  const [answers, setAnswers] = useState([]);
  const {addQuestion} = useQuestions();
  const history = useHistory();

  useEffect(() => {
    return question.difficulty;
  }, []);

  const handleChange = field => event => {
    question[field] = event.target.value;
    setQuestion(question);
  };

  const handleSave = async () => {
    question.answers = answers;
    const document = await addQuestion(question);
    history.push(`/admin/questions/${document.id}`);
  };

  return <form
    autoComplete="off"
    noValidate
  >
    <div className={'flex flex-col'}>
      <H2>New Question</H2>

      <div className={'mb-4'}>
        <TextField
          label={'FirestoreQuestion Text'}
          onChange={handleChange('text')}
          value={question.text}/>
      </div>
      <div className={'mb-4'}>
        <TextField
          label={'Answer(s)'}
          multiline
          onChange={event => {
            setAnswers(event.target.value.split('\n'));
          }}
          value={answers.join('\n')}/>
      </div>
      <div className={'mb-4'}>
        <TextField
          label={'Source'}
          onChange={handleChange('source')}
          value={question.source}/>
      </div>

      <div>
        <div className={'font-light text-3xl'}>Difficulty</div>
        <Rating
          onChange={value => {
            const formattedEvent = {
              target: {
                value
              }
            };

            handleChange('difficulty')(formattedEvent);
          }}
          value={question.difficulty}
        />
      </div>

      <div className={'mb-4'}>
        <TagInput
          onChange={value => {
            const formattedEvent = {
              target: {
                value
              }
            };

            handleChange('tags')(formattedEvent);
          }}/>
      </div>

      <div className={'flex'}>
        <ThemedButton
          onClick={handleSave}
          theme={'primary'}>
          <IconDeviceFloppy/> Save
        </ThemedButton>
      </div>
    </div>
  </form>;

};

export default NewQuestion;
