import {IconPencil} from '@tabler/icons-react';
import {useHistory} from 'react-router-dom';

import useVenues from '../../../../hooks/useVenues';
import {ConditionalTable, ThemedTooltipIconButton} from '../../../../molecules';

const venuesColumns = [{
  Header: 'Name',
  accessor: 'name'
}, {
  Header: 'Actions',
  accessor: 'actions'
}];

const Venues = () => {
  const history = useHistory();
  const {venues, venuesLoading} = useVenues();

  const viewVenue = venueId => () => history.push(`/app/venues/${venueId}`);

  const getMappedVenues = () => venues?.map(venue => ({
    actions: <ThemedTooltipIconButton
      onClick={viewVenue(venue?.id)}
      text={'Edit Venue'}>
      <IconPencil strokeWidth={1}/>
    </ThemedTooltipIconButton>,
    name: venue.data().name
  }));

  return <ConditionalTable
    columns={venuesColumns}
    data={getMappedVenues()}
    loading={venuesLoading}/>;
};

export default Venues;
