import {Button, Dialog, DialogActions, DialogContent, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import {useState} from 'react';

const NewTeam = props => {
  const [teamName, setTeamName] = useState('');

  const handleChange = event => {
    setTeamName(event.target.value);
  };

  const handleClose = value => () => props.onClose(value);

  return (
    <Dialog open={props.open}>
      <DialogContent>
        <TextField
          onChange={handleChange}
          value={teamName}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose(false)}>Cancel</Button>
        <Button onClick={handleClose(teamName)}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

NewTeam.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};

NewTeam.defaultProps = {
  onClose: () => {
  },
  open: false
};

export default NewTeam;