import PropTypes from 'prop-types';
import {Tooltip} from '../../atoms';

export const drawerMenuIconProps = {
  height: 32,
  strokeWidth: '1',
  width: 32
};

const DrawerMenuItem = ({href, icon, text}) => {
  return <Tooltip>
    <div
      className={'h-[60px] max-h-[60px] p-1 cursor-pointer'}
      data-tip={text}>
      <a
        className={'flex flex-row h-full items-center p-2 rounded-lg hover:bg-gray-200 hover:text-sky-500 ease-in duration-150'}
        href={href}>
        <div className={'pr-2'}>{icon}</div>
        <div className={'overflow-hidden'}>{text}</div>
      </a>
    </div>
  </Tooltip>;
};

DrawerMenuItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.node,
  text: PropTypes.string
};

export default DrawerMenuItem;
