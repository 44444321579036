import PropTypes from 'prop-types';
import {Route, Switch} from 'react-router-dom';
import {H1} from '../../atoms';

const RouteRenderer = ({routes}) => {
  return <Switch>
    {routes?.map(route => <Route
      exact
      key={route.path}
      path={route.path}>
      {route.heading && <H1>{route.heading}</H1>}
      {route.component}
    </Route>)}
  </Switch>;
};

RouteRenderer.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({
    component: PropTypes.node,
    heading: PropTypes.string,
    path: PropTypes.string
  }))
};

export default RouteRenderer;
