import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import PropTypes from 'prop-types';

const ConfirmationDialog = ({message, open, onClose}) => {

  const handleClose = confirmation => () => {
    return onClose(confirmation);
  };

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={handleClose(false)}
      open={open}
    >
      <DialogTitle id="alert-dialog-title">
        {'Confirmation'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose(false)}>Cancel</Button>
        <Button
          autoFocus
          onClick={handleClose(true)}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

ConfirmationDialog.defaultProps = {
  message: 'Are you sure?',
  onClose: () => {
  },
  open: false
};

export default ConfirmationDialog;
