import {Route, Switch, useRouteMatch} from 'react-router-dom';
import LiveEvent from '../liveEvent/LiveEvent';
import Remote from '../remote/remote';
import Slide from '../slide/slide';
import FirestorePrintout from './printout/firestorePrintout';

const Tools = () => {
  const {path} = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/slide`}>
        <Slide/>
      </Route>
      <Route path={`${path}/remote`}>
        <Remote/>
      </Route>
      <Route path={`${path}/slideshows/:slideshowId`}>
        <Slide/>
      </Route>
      <Route path={`${path}/printout/:slideshowId`}>
        <FirestorePrintout/>
      </Route>
      <Route path={`${path}/live/:eventId`}>
        <LiveEvent/>
      </Route>
    </Switch>
  );
};

export default Tools;
