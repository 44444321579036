import PropTypes from 'prop-types';
import ChildrenModel from '../../models/Children';

const Avatar = ({backgroundColor, children, color}) => {
  return <div className={`flex items-center justify-center font-medium text-2xl h-8 w-8 p-4 rounded-full ${color} ${backgroundColor}`}>{children}</div>;
};

Avatar.propTypes = {
  backgroundColor: PropTypes.string,
  children: ChildrenModel,
  color: PropTypes.string
};

Avatar.defaultProps = {
  backgroundColor: 'bg-gray-400',
  color: 'font-gray-700'
};

export default Avatar;
