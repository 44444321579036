import {useRouteMatch} from 'react-router-dom';

import {DialogWrapper} from '../../../proteins';
import RouteRenderer from '../../routeRenderer/routeRenderer';
import FirestoreQuestion from '../app/question/firestoreQuestion';
import TypeSenseQuestions from '../app/questions/typeSenseQuestions';
import AdminWrapper from './adminWrapper/adminWrapper';
import Companies from './companies/companies';
import FirestoreCompany from './company/firestoreCompany';
import ContactRequests from './contactRequests/contactRequests';
import Dashboard from './dashboard/dashboard';
import NewBulkQuestions from './newBulkQuestions/newBulkQuestions';
import NewQuestion from './newQuestion/newQuestion';
import FirestorePrebuiltRound from './prebuiltRound/firestorePrebuiltRound';
import PrebuiltRounds from './prebuiltRounds/prebuiltRounds';
import SuggestedQuestions from './suggestedQuestions/suggestedQuestions';
import Tags from './tags/tags';

const routes = path => [{
  component: <Dashboard/>,
  path: `${path}/`
}, {
  component: <Dashboard/>,
  path: `${path}/dashboard`
}, {
  component: <Tags/>,
  path: `${path}/tags`
}, {
  component: <NewQuestion/>,
  path: `${path}/questions/new`
}, {
  component: <NewBulkQuestions/>,
  path: `${path}/questions/new/bulk`
}, {
  component: <SuggestedQuestions/>,
  heading: 'Suggested Questions',
  path: `${path}/questions/suggested`
}, {
  component: <FirestoreQuestion/>,
  path: `${path}/questions/:questionId`
}, {
  component: <TypeSenseQuestions/>,
  path: `${path}/questions`
}, {
  component: <PrebuiltRounds/>,
  heading: 'Prebuilt Rounds',
  path: `${path}/prebuiltRounds`
}, {
  component: <FirestorePrebuiltRound/>,
  path: `${path}/prebuiltRounds/:prebuiltRoundId`
}, {
  component: <FirestoreCompany/>,
  path: `${path}/companies/:companyId`
}, {
  component: <Companies/>,
  heading: 'Companies',
  path: `${path}/companies`
}, {
  component: <ContactRequests/>,
  heading: 'Contact Requests',
  path: `${path}/contacts/requests`
}];

const Index = () => {
  const {path} = useRouteMatch();

  return <AdminWrapper>
    <DialogWrapper>
      <RouteRenderer routes={routes(path)}/>
    </DialogWrapper>
  </AdminWrapper>;
};

export default Index;
