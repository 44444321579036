import PropTypes from 'prop-types';
import Children from '../../models/Children';

const FAB = ({children, color, hoverColor, iconColor, onClick, ...rest}) => {
  return <div
    className={`flex items-center justify-center rounded-full h-12 w-12 cursor-pointer drop-shadow-xl ease-in-out duration-300 ${iconColor} ${color} ${hoverColor}`}
    onClick={onClick}
    {...rest}>
    {children}
  </div>;
};

FAB.propTypes = {
  children: Children,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  iconColor: PropTypes.string,
  onClick: PropTypes.func
};

FAB.defaultProps = {
  color: 'bg-sky-500',
  hoverColor: 'hover:bg-sky-700',
  iconColor: 'text-white'
};

export default FAB;
