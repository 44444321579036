import PropTypes from 'prop-types';
import {H6} from '../../atoms';

const AppBar = ({text}) => {
  return <div className={'h-14 px-2 bg-sky-500 text-white font-black flex items-center px-4 fixed right-0 top-0 w-full justify-center shadow z-40'}>
    <H6>{text}</H6>
  </div>;
};

AppBar.propTypes = {
  text: PropTypes.string
};

export default AppBar;
