import {collection, getFirestore, query, where} from 'firebase/firestore';
import {useCollection} from 'react-firebase-hooks/firestore';

import hardcodes from '../config/hardcodes';
import {firebaseApp} from '../firebaseApp';

const useSlideshowsCollection = (eventId = null, companyId = hardcodes.companyId) => {
  let firebaseCollection = query(collection(getFirestore(firebaseApp), 'slideshows'),
    where('companyId', '==', companyId));

  if (eventId) {
    firebaseCollection = query(firebaseCollection, where('event', '==', eventId));
  }

  const [slideshowsCollection, loading, error] = useCollection(
    firebaseCollection,
    {
      snapshotListenOptions: {includeMetadataChanges: true}
    }
  );

  return {
    error,
    loading,
    slideshowCollection: slideshowsCollection?.docs
  };
};

export default useSlideshowsCollection;
