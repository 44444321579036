import PropTypes from 'prop-types';

import {H2, H3} from '../../../../atoms';
import {ChipList, ConditionalSpinner} from '../../../../molecules';
import TypeSenseQuestions from '../../app/questions/typeSenseQuestions';

const PrebuiltRound = ({loading, prebuiltRound, prebuiltRoundId, setPrebuiltRound}) => {
  return <ConditionalSpinner showSpinnerWhen={loading}>
    <H2>{prebuiltRound?.title}</H2>
    <div className={'mb-4'}>
      {prebuiltRound?.description}
    </div>
    <div className={'mb-4'}>
      <H3>Tags</H3>
      <ChipList
        chips={prebuiltRound?.tags}
      />
    </div>
    <TypeSenseQuestions
      rowSelected={data => {
        if (data.selected && prebuiltRound?.questions?.indexOf(data.questionId) === -1) {
          prebuiltRound.questions.push(data.questionId);
        } else if (!data.selected && prebuiltRound?.questions?.indexOf(data.questionId) > -1) {
          prebuiltRound.questions = prebuiltRound.questions.filter(question => question !== data.questionId);
        }
        prebuiltRound && setPrebuiltRound(prebuiltRound, prebuiltRoundId);
      }}
      selectedQuestions={prebuiltRound?.questions}
    />
  </ConditionalSpinner>;
};

PrebuiltRound.propTypes = {
  loading: PropTypes.bool,
  prebuiltRound: PropTypes.shape({
    description: PropTypes.string,
    questions: PropTypes.array,
    tags: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string
  }),
  prebuiltRoundId: PropTypes.string,
  setPrebuiltRound: PropTypes.func
};

export default PrebuiltRound;
