import PropTypes from 'prop-types';

export const YEARLY = 'yearly';
export const MONTHLY = 'monthly';

export const MONTHS_PER_YEAR = 12;

export const isYearly = price => price.frequency === YEARLY;
export const isMonthly = price => price.frequency === MONTHLY;

const frequencyStringMapping = [];

frequencyStringMapping[MONTHLY] = 'mo';
frequencyStringMapping[YEARLY] = 'yr';

export const getPriceFrequencyString = frequency => {
  return frequencyStringMapping[frequency];
};

export const getEventFrequencyString = plan => {
  if (!plan.eventsPerMonth || plan.eventsPerMonth < 1) {
    return;
  }

  if (plan.eventsPerMonth === 1) {
    return '1 event/month';
  } else if (plan.eventsPerMonth >= 31) {
    return `${Math.round(plan.eventsPerMonth / 31)} events/day`;
  }
};

export const getPriceByFrequency = (yearly, plan) => {
  let condition = isMonthly;

  if (yearly) {
    condition = isYearly;
  }

  return getPrice(condition, plan);
};

export const getSavings = plan => {
  const monthlyPrice = getPriceByFrequency(false, plan);
  const yearlyPrice = getPriceByFrequency(true, plan);

  const extendedMonthlyPrice = MONTHS_PER_YEAR * monthlyPrice;

  if (yearlyPrice < extendedMonthlyPrice) {
    return Math.round((extendedMonthlyPrice - yearlyPrice) / extendedMonthlyPrice * 100);
  }

  return 0;
};

const getPrice = (condition, plan) => {
  let price = 0;
  const prices = plan.prices.filter(condition);

  if (prices && prices[0]) {
    price = prices[0].price;
  }
  return price;
};

const getPriceFromPlan = (yearly, plan) => {
  return getPriceByFrequency(yearly, plan);
};

const PricingCard = ({plan, yearly}) => {

  return (
    <div className="col">
      <div className="card mb-4 rounded-3 shadow-sm border-info">
        <div className="card-header py-3 text-white bg-info border-info">
          <h4 className="my-0 fw-normal">{plan.name}</h4>
        </div>

        {yearly && getSavings(plan) > 0 && <div className="card-header py-3 text-black bg-warning border-warning">
          <h4 className="my-0 fw-normal">{getSavings(plan)}% Savings!</h4>
        </div>}
        <div className="card-body">
          <h1 className="card-title pricing-card-title">${getPriceFromPlan(yearly, plan)}<small
            className="text-muted fw-light">/{getPriceFrequencyString(yearly ? YEARLY : MONTHLY)}</small></h1>
          <ul className="list-unstyled mt-3 mb-4">
            <li>{plan.maxUsers || 'Unlimited'} users included</li>
            <li>{getEventFrequencyString(plan)}</li>
            {plan?.features?.map((feature, index) => (
              <li key={`features-${index}`}>{feature}</li>
            ))}
          </ul>
          <button
            className="w-100 btn btn-lg btn-info text-light"
            type="button">Sign up {getPriceFromPlan(yearly, plan) ? 'now' : 'for free'}</button>
        </div>
      </div>
    </div>
  );
};

PricingCard.propTypes = {
  plan: PropTypes.shape({
    eventsPerMonth: PropTypes.number,
    features: PropTypes.arrayOf(PropTypes.string),
    maxUsers: PropTypes.number,
    name: PropTypes.string,
    prices: PropTypes.arrayOf(PropTypes.shape({
      frequency: PropTypes.string,
      price: PropTypes.number
    }))
  }),
  yearly: PropTypes.bool
};

export default PricingCard;
