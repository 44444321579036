import {useParams} from 'react-router-dom';

import {H3, HR, Paper} from '../../../../atoms';
import hardcodes from '../../../../config/hardcodes';
import useEvent from '../../../../hooks/useEvent';
import useSlideshow from '../../../../hooks/useSlideshow';
import {ConditionalSpinner} from '../../../../molecules';
import SlideTemplateRenderer from '../../../SlideTemplateRenderer/SlideTemplateRenderer';
import mappings from '../slide/slideTemplateMappings';
import ScoreTable from './ScoreTable';

export default function Scoreboard() {
  const {slideshow, getCurrentSlide} = useSlideshow(hardcodes.slideshowId);
  const {id: eventId} = useParams();

  const {event, eventLoading} = useEvent(eventId);

  const slide = getCurrentSlide();

  const Template = slide && mappings[slide.template];

  return <ConditionalSpinner showSpinnerWhen={eventLoading}>
    <div className={'flex-grow'}>
      <H3>
        Scoreboard - {event?.name}
      </H3>
      <HR/>
      <div className={'flex justify-center'}>
        <Paper
          className={'flex items-center justify-center h-[240px] w-full'}>
          {(slideshow && !!Template) &&
            <SlideTemplateRenderer
              Template={Template}
              templateProps={slide.props}/>}
        </Paper>
      </div>
      <br/>
      <ScoreTable/>
    </div>
  </ConditionalSpinner>;
}
