import {makeStyles} from '@mui/styles';
import {IconPlus} from '@tabler/icons-react';

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%'
  }
}));

const AddSlideTemplate = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <IconPlus/>
    </div>
  );
};

export default AddSlideTemplate;
