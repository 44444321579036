import {indexOf as _indexOf, random as _random} from 'lodash';
import PropTypes from 'prop-types';
import {ReactSortable} from 'react-sortablejs';

import usePrebuiltRound from '../../hooks/usePrebuiltRound';
import RoundModel from '../../models/Round';
import {noop} from '../../utils';
import {getRoundHeader} from '../../utils/rounds';
import FirestoreBoardSlide from '../boardSlide/firestoreBoardSlide';
import BoardRoundActions from './boardRoundActions';

const BoardRound = ({
  addNewSlide,
  deleteRound,
  deleteSlide,
  index,
  round,
  saveRound,
  saveSlide,
  showPrebuiltRoundSelectionDialog,
  showQuestionSelectionDialog,
  showRenameDialog
}) => {

  let prebuiltRound = {
    questions: []
  };

  if (round.prebuiltRoundId) {
    const {prebuiltRound: newPrebuiltRound} = usePrebuiltRound((round?.prebuiltRoundId) || '');

    if (newPrebuiltRound) {
      prebuiltRound = newPrebuiltRound;
    }
  }

  const onClickAddSlide = () => {
    addNewSlide(index);
  };

  const onClickEditSlide = (index, round) => {
    showQuestionSelectionDialog(round, index);
  };

  const onClickDeleteSlide = (index, round) => {
    deleteSlide(round, index);
  };

  const onClickDeleteRound = () => {
    deleteRound(index);
  };

  const onClickRefreshAll = () => {
    const numberOfQuestions = round.questionCount;

    round.slides = [];

    for (let i = 0; i < numberOfQuestions; i++) {
      const usedQuestions = round?.slides?.map(slide => slide.questionId);
      const filteredQuestions = prebuiltRound?.questions?.filter(slide => _indexOf(usedQuestions, slide.questionId) === -1);

      const randomIndex = _random(0, filteredQuestions.length);

      round.slides[i] = {questionId: filteredQuestions[randomIndex]};
    }

    saveRound({...round, number: index});
  };

  const sortRound = slides => {
    saveRound({...round, number: index, slides: slides});
  };

  const getRounds = () => round.slides;

  return <div
    className={'flex flex-col h-fit bg-gray-400 border-1 border-gray-700 mr-3 p-3 w-[400px] min-w-[400px] text-white px-auto rounded mr-1'}>
    <div className={'text-3xl font-thin pb-0'}>{getRoundHeader(round, prebuiltRound)}</div>
    <BoardRoundActions
      index={index}
      onClickAddSlide={onClickAddSlide}
      onClickDeleteRound={onClickDeleteRound}
      onClickRefreshAll={onClickRefreshAll}
      prebuiltRound={prebuiltRound}
      round={round}
      showPrebuiltRoundSelectionDialog={showPrebuiltRoundSelectionDialog}
      showRenameDialog={showRenameDialog}
    />
    <ReactSortable
      animation={200}
      handle={'.handle'}
      list={getRounds() || []}
      setList={sortRound}>
      {round?.slides?.map((slide, index) => (
        <FirestoreBoardSlide
          deleteSlide={onClickDeleteSlide}
          editSlide={onClickEditSlide}
          index={index}
          key={`${round.number}-${index}`}
          questionOptions={prebuiltRound?.questions}
          round={round}
          saveSlide={saveSlide}
          slide={slide}/>))}
    </ReactSortable>
  </div>;
};

BoardRound.propTypes = {
  addNewSlide: PropTypes.func,
  deleteRound: PropTypes.func,
  deleteSlide: PropTypes.func,
  index: PropTypes.number,
  round: RoundModel,
  saveRound: PropTypes.func,
  saveSlide: PropTypes.func,
  showPrebuiltRoundSelectionDialog: PropTypes.func,
  showQuestionSelectionDialog: PropTypes.func,
  showRenameDialog: PropTypes.func
};

BoardRound.defaultProps = {
  addNewSlide: noop,
  deleteRound: noop,
  deleteSlide: noop,
  round: {
    slides: []
  },
  saveRound: noop,
  saveSlide: noop,
  showQuestionSelectionDialog: noop,
  showRenameDialog: noop
};

export default BoardRound;
