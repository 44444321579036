import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';
import PropTypes from 'prop-types';

const RenameRoundDialog = (props) => {
  return <Dialog
    onClose={props.onClose}
    open={props.open}>
    <DialogTitle>Rename Round</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        fullWidth
        margin="dense"
        value={(typeof props.roundToEdit.number !== 'number' ? props.roundToEdit.number : '')}
        variant="standard"
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose}>Cancel</Button>
      <Button onClick={props.onClick}>Save</Button>
    </DialogActions>
  </Dialog>;
};

RenameRoundDialog.propTypes = {
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  roundToEdit: PropTypes.shape({
    number: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })
};

export default RenameRoundDialog;
