import PropTypes from 'prop-types';

import SlideWrapper from '../slideTemplates/slideWrapper';

const BoardSlideshowTransitionSlide = ({round, showingAnswers, small, ...rest}) => {
  return <SlideWrapper {...rest}>
    <div className={'flex flex-col justify-between h-full w-full p-4 pt-[200] bg-blue-300'}>
      <div className={`${small ? 'text-2xl' : 'text-6xl'}`}>Round {round}{showingAnswers && ' Answers'}</div>
    </div>
  </SlideWrapper>;
};

BoardSlideshowTransitionSlide.propTypes = {
  round: PropTypes.number,
  showingAnswers: PropTypes.bool,
  small: PropTypes.bool
};

export default BoardSlideshowTransitionSlide;
