import {IconPencil} from '@tabler/icons-react';
import {useHistory} from 'react-router-dom';

import useCompanyUsers from '../../../../hooks/useCompanyUsers';
import {ConditionalTable, ThemedAvatar, ThemedTooltipIconButton} from '../../../../molecules';
import getInitials from '../../../../utils/getInitials';

const hostColumns = [{
  Header: '',
  accessor: 'avatar'
}, {
  Header: 'Name',
  accessor: 'name'
}, {
  Header: 'Actions',
  accessor: 'actions'
}];

const Hosts = () => {
  const history = useHistory();
  const {companyUsers, companyUsersLoading} = useCompanyUsers();

  const viewHost = hostId => () => history.push(`/app/hosts/${hostId}`);

  const getMappedUsers = () => companyUsers?.map(user => ({
    actions: <ThemedTooltipIconButton
      onClick={viewHost(user?.id)}
      text={'Edit Host'}>
      <IconPencil strokeWidth={1}/>
    </ThemedTooltipIconButton>,
    avatar: <ThemedAvatar>{getInitials(user.data().name)}</ThemedAvatar>,
    name: user.data().name
  }));

  return <ConditionalTable
    columns={hostColumns}
    data={getMappedUsers()}
    loading={companyUsersLoading}/>;
};

export default Hosts;
