import {IconPencil, IconPlus, IconRefresh, IconReplace, IconTrash} from '@tabler/icons-react';
import PropTypes from 'prop-types';

import {ThemedTooltipIconButton} from '../../molecules';

const BoardRoundActions = ({
  index,
  onClickAddSlide,
  onClickDeleteRound,
  onClickRefreshAll,
  prebuiltRound,
  round,
  showPrebuiltRoundSelectionDialog,
  showRenameDialog
}) => {

  const isPrebuiltRound = () => prebuiltRound?.questions?.length > 0;

  return <div className={'flex flex-row justify-end'}>
    {!isPrebuiltRound() ? <ThemedTooltipIconButton
      onClick={() => {
        showRenameDialog(round, index);
      }}
      text={'Rename Round'}>
      <IconPencil strokeWidth={1}/>
    </ThemedTooltipIconButton> : <ThemedTooltipIconButton
      onClick={showPrebuiltRoundSelectionDialog}
      text={'Change Round'}
    >
      <IconReplace strokeWidth={1}/>
    </ThemedTooltipIconButton>}
    <ThemedTooltipIconButton
      onClick={onClickDeleteRound}
      text={'Delete Round'}>
      <IconTrash strokeWidth={1}/>
    </ThemedTooltipIconButton>
    <ThemedTooltipIconButton
      onClick={onClickAddSlide}
      text={'Add Slide'}>
      <IconPlus strokeWidth={1}/>
    </ThemedTooltipIconButton>
    {isPrebuiltRound() && <ThemedTooltipIconButton
      onClick={onClickRefreshAll}
      text={'Refresh All Questions'}>
      <IconRefresh strokeWidth={1}/>
    </ThemedTooltipIconButton>}
  </div>;
};

BoardRoundActions.propTypes = {
  index: PropTypes.number,
  onClickAddSlide: PropTypes.func,
  onClickDeleteRound: PropTypes.func,
  onClickRefreshAll: PropTypes.func,
  prebuiltRound: PropTypes.shape({
    questions: PropTypes.array
  }),
  round: PropTypes.any,
  showPrebuiltRoundSelectionDialog: PropTypes.func,
  showRenameDialog: PropTypes.func
};

export default BoardRoundActions;
