import {doc, getFirestore, setDoc} from 'firebase/firestore';

import {firebaseApp} from '../firebaseApp';
import useTriviaDocument from './useTriviaDocument';

const usePrebuiltRound = prebuiltRoundId => {
  const [document, loading, error] = useTriviaDocument('prebuiltRounds', prebuiltRoundId);

  const prebuiltRound = document?.data();


  const setPrebuiltRound = async (prebuildRound, prebuildRoundId = prebuildRoundId) => {
    return await setDoc(doc(getFirestore(firebaseApp), 'prebuiltRounds', prebuildRoundId), prebuildRound);
  };

  return {
    prebuiltRound,
    prebuiltRoundError: error,
    prebuiltRoundLoading: loading,
    setPrebuiltRound
  };
};

export default usePrebuiltRound;
