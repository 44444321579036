import {where} from 'firebase/firestore';
import hardcodes from '../config/hardcodes';
import useTriviaCollection from './useTriviaCollection';

const useVenues = (companyId = hardcodes.companyId) => {
  const [venues, venuesLoading, venuesError] = useTriviaCollection('venues', [where('companyId', '==', companyId)]);

  return {
    venues: venues ? venues.docs : [],
    venuesError,
    venuesLoading
  };
};

export default useVenues;
