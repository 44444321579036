import {useParams} from 'react-router-dom';

import useVenue from '../../../../hooks/useVenue';
import Venue from './venue';

const FirestoreVenue = () => {
  const {venueId} = useParams();
  const {venue, venueLoading} = useVenue(venueId);

  return <Venue
    venue={venue}
    venueLoading={venueLoading}/>;
};

export default FirestoreVenue;
